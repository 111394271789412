import React, { useEffect } from "react";
import "../css/tnc.css";
import { FaRegCheckCircle } from "react-icons/fa";

const TnC = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  })
  return (
    <div className="tnc-container">
      <h2 className="tnc-title">Terms and Conditions</h2>
      <ul className="tnc-list">
        <li className="tnc-item" data-wow-delay="0.1s">
          <FaRegCheckCircle className="tnc-icon" />
          Customers are not allowed to move or rearrange any properties or goods
          set up by MiniFlicks, maintaining the integrity of the theatre setup.
        </li>
        <li className="tnc-item" data-wow-delay="0.2s">
          <FaRegCheckCircle className="tnc-icon" />
          Customers are not allowed to bring outside food, Spark candles, and
          snow sprays into the theatre, ensuring cleanliness and compliance with
          facility regulations.
        </li>
        <li className="tnc-item" data-wow-delay="0.3s">
          <FaRegCheckCircle className="tnc-icon" />
          Customers should not make adjustments or modifications to the setup of
          projector, screen, laptop and sound system. If any changes are
          required, we will do it from our end.
        </li>
        <li className="tnc-item" data-wow-delay="0.4s">
          <FaRegCheckCircle className="tnc-icon" />
          Individuals below 18 years of age cannot book the theater. Legal
          guardians can book the theater and bring their minor ward(s) along
          with them.
        </li>
        <li className="tnc-item" data-wow-delay="0.4s">
          <FaRegCheckCircle className="tnc-icon" />
          Aadhar card is mandatory of couples while check-in the Theatre.
        </li>
        <li className="tnc-item" data-wow-delay="0.6s">
          <FaRegCheckCircle className="tnc-icon" />
          Customers will be liable to pay in case of any damage to any of the
          technical or non-technical items in the theater including the walls,
          lights, seating, etc. caused by them, promoting responsible and
          respectful use of the facilities.
        </li>
        <li className="tnc-item" data-wow-delay="0.7s">
          <FaRegCheckCircle className="tnc-icon" />
          In cases where cleaners would be required to clean the garbage,
          leftovers and other wastes after the customers check out, the
          customers will have to pay appropriate cleaning fee.
        </li>
        <li className="tnc-item" data-wow-delay="0.8s">
          <FaRegCheckCircle className="tnc-icon" />
          Customers are not allowed to smoke, consume alcoholic beverages or any
          illegal substance inside the theater. Appropriate action would be
          taken against such customers.
        </li>
        <li className="tnc-item" data-wow-delay="0.9s">
          <FaRegCheckCircle className="tnc-icon" />
          MiniFlicks does not provide content for streaming; customers must use
          their own accounts, and necessary facilities will be provided
          outlining the customer's responsibility for their streaming content.
        </li>
        <li className="tnc-item" data-wow-delay="1s">
          <FaRegCheckCircle className="tnc-icon" />
          MiniFlicks reserves the right to cancel bookings due to unforeseen
          circumstances, and customers will receive a refund within 5-7 working
          days, providing transparency and assurance in the event of unforeseen
          changes.
        </li>
        <li className="tnc-item" data-wow-delay="1.1s">
          <FaRegCheckCircle className="tnc-icon" />
          MiniFlicks does not take responsibility for any damage, theft, or loss
          of vehicles parked on the premises. Customers are advised to park
          their vehicles at their own risk, clarifying the responsibility for
          vehicle security.
        </li>
      </ul>
    </div>
  );
};

export default TnC;
