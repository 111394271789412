import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";
import axios from "axios";
import bouquet from "../cover/bouquet.webp";
import teddycover from "../cover/teddycover.webp";
import pillow from "../cover/pillow.webp";
import catcover from "../cover/catcover.jpg";
import { useNavigate } from "react-router-dom";
import "../css/summary.model.css";

const SummaryModel = () => {
  const username = localStorage.getItem("username");
  const email = localStorage.getItem("email");
  const whatsappNo = localStorage.getItem("whatsappNo");
  const selectedDate = localStorage.getItem("selectedDate");
  const selectedTime = localStorage.getItem("selectedTime");
  const selectedTheatre = localStorage.getItem("selectedTheatre");
  const selectedTheatreType = localStorage.getItem("selectedTheatreType");
  const selectedTheatrePrice = Number(
    localStorage.getItem("selectedTheatrePrice")
  );
  const selectedDecorationType = localStorage.getItem("selectedDecorationType");
  const selectedDecorationDetails = localStorage.getItem(
    "selectedDecorationDetails"
  );
  const selectedCake = localStorage.getItem("selectedCake");
  const selectedCakePrice = Number(localStorage.getItem("selectedCakePrice"));
  const totalAdultPrice = Number(localStorage.getItem("totalAdultPrice")) || 0;
  const nameonCake = localStorage.getItem("nameonCake");
  const specialAddons = JSON.parse(localStorage.getItem("specialAddons")) || {};
  const ledNamePrice = specialAddons.ledName || "";
  const ledNumberPrice = specialAddons.ledNumber || "";
  const specialAddonsValue =
    JSON.parse(localStorage.getItem("specialAddonsValue")) || {};
  const ledName = specialAddonsValue.ledNameValue || "";
  const ledNumber = specialAddonsValue.ledNumberValue || "";
  const localhost = process.env.REACT_APP_LOCALHOST;
  const awsProdPublicDomain = process.env.REACT_APP_AWS_EC2_DOMAIN;

  const handleShowTermsModal = () => setShowTermsModal(true);
  const handleCloseTermsModal = () => setShowTermsModal(false);

  const handleShowRefundModal = () => setShowRefundModal(true);
  const handleCloseRefundModal = () => setShowRefundModal(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const initialOptionalGifts = JSON.parse(
    localStorage.getItem("optionalGifts")
  ) || {
    rose: "NA",
    teddy: "NA",
    heartPillow: "NA",
    cat: "NA",
  };
  const [roseSelected, setRoseSelected] = useState(
    initialOptionalGifts.rose === "Selected"
  );
  const [teddySelected, setTeddySelected] = useState(
    initialOptionalGifts.teddy === "Selected"
  );
  const [heartPillowSelected, setHeartPillowSelected] = useState(
    initialOptionalGifts.heartPillow === "Selected"
  );
  const [catSmallSelected, setCatSmallSelected] = useState(
    initialOptionalGifts.cat === "Selected"
  );

  const updateOptionalGifts = () => {
    const updatedGifts = {
      rose: roseSelected ? "Selected" : "NA",
      teddy: teddySelected ? "Selected" : "NA",
      heartPillow: heartPillowSelected ? "Selected" : "NA",
      cat: catSmallSelected ? "Selected" : "NA",
    };
    localStorage.setItem("optionalGifts", JSON.stringify(updatedGifts));
  };

  useEffect(() => {
    updateOptionalGifts();
  }, [roseSelected, teddySelected, heartPillowSelected, catSmallSelected]);

  const [copoun, setCopoun] = useState("");
  const [copounValue, setCopounValue] = useState(0);
  const [msg, setMsg] = useState(null);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);

  const prices = {
    rose: 299,
    teddy: 299,
    heartPillow: 199,
    smallCat: 299,
  };

  console.log(selectedTheatrePrice + totalAdultPrice + selectedCakePrice);
  const calculateTotal = () => {
    let total = selectedTheatrePrice + totalAdultPrice + selectedCakePrice;
    if (roseSelected) total += prices.rose;
    if (teddySelected) total += prices.teddy;
    if (heartPillowSelected) total += prices.heartPillow;
    if (catSmallSelected) total += prices.smallCat;
    if (ledNamePrice !== "NA") total += Number(ledNamePrice);
    if (ledNumberPrice !== "NA") total += Number(ledNumberPrice);
    return total;
  };

  const handleCheckCopounCode = async (e) => {
    e.preventDefault();
    try {
      // const response = await axios.post(
      //   `${localhost}/admin/check-copoun-code`,
      //   { copoun }
      // );
      const response = await axios.post(
        `${awsProdPublicDomain}/admin/check-copoun-code`,
        { copoun }
      );
      if (response.data) {
        setMsg(response.data);
        setMsg(response.data);
        if (response.data.message === "Hurrah!! Coupon Applied!") {
          const numericValue = parseInt(response.data.data.match(/\d+/)[0], 10);
          setCopounValue(numericValue);
          localStorage.setItem("copounCode", numericValue);
        } else {
          setCopounValue(0);
        }
      }
    } catch (err) {
      console.error("Error applying coupon:", err);
      setMsg({ message: "An unexpected error occurred" });
    }
  };

  const handleOptionalGifts = (gift, event) => {
    // event.preventDefault();
    switch (gift) {
      case "rose":
        setRoseSelected(!roseSelected);
        break;
      case "teddy":
        setTeddySelected(!teddySelected);
        break;
      case "heartPillow":
        setHeartPillowSelected(!heartPillowSelected);
        break;
      case "cat":
        setCatSmallSelected(!catSmallSelected);
        break;
      default:
        break;
    }
  };

  const handleCheckboxChange = (event) => {
    setAgreeToTerms(event.target.checked);
  };

  const proceedPay = async () => {
    const dataPayload = {
      name: username,
      number: whatsappNo,
      merchantTransactionId: "Trnx" + Date.now(),
    };
    localStorage.setItem(
      "remainingAmount",
      calculateTotal() - 999 - copounValue
    );

    try {
      alert("Proceeding to Payment! Don't Refresh");
      // const res = await axios.post(`${localhost}/paymentcheckout`, dataPayload);
      const res = await axios.post(
        `${awsProdPublicDomain}/paymentcheckout`,
        dataPayload
      );
      if (res.data && res.data.data.instrumentResponse.redirectInfo.url) {
        window.location.href =
          res.data.data.instrumentResponse.redirectInfo.url;
      }
    } catch (error) {
      console.log("Payment error:", error);
    }
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
    localStorage.setItem("totalAmount", calculateTotal());
  }, [
    roseSelected,
    teddySelected,
    heartPillowSelected,
    catSmallSelected,
    copounValue,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("copounCode" ,"NA")
  }, []);

  return (
    <Container>
      <Row className="justify-content-center mt-5">
        <Col md={8}>
          <Card className="custom-summary-card">
            <Card.Header className="custom-summary-header">
              <h4 className="custom-summary-title">Booking Summary</h4>
            </Card.Header>
            <Card.Body>
              {/* Personal Details */}
              <Row>
                <Col sm={6}>
                  <h5 className="custom-summary-subtitle">Personal Details</h5>
                  <p>
                    <strong style={{ color: "#007bff" }}>Name:</strong>{" "}
                    <span style={{ color: "#000000" }}>{username}</span>
                  </p>
                  <p>
                    <strong style={{ color: "#007bff" }}>Email:</strong>{" "}
                    <span style={{ color: "#000000" }}>{email}</span>
                  </p>
                  <p>
                    <strong style={{ color: "#007bff" }}>WhatsApp No:</strong>{" "}
                    <span style={{ color: "#000000" }}>{whatsappNo}</span>
                  </p>
                </Col>

                {/* Theatre details */}
                <Col sm={6}>
                  <h5 className="custom-summary-subtitle">Theatre Details</h5>
                  <p>
                    <strong style={{ color: "#007bff" }}>Theatre:</strong>{" "}
                    <span style={{ color: "#000000" }}>{selectedTheatre}</span>
                  </p>
                  <p>
                    <strong style={{ color: "#007bff" }}>Package Type:</strong>{" "}
                    <span style={{ color: "#000000" }}>
                      {selectedTheatreType}
                    </span>
                  </p>
                  <p>
                    <strong style={{ color: "#007bff" }}>Date:</strong>{" "}
                    <span style={{ color: "#000000" }}>{selectedDate}</span>
                  </p>
                  <p>
                    <strong style={{ color: "#007bff" }}>Time:</strong>{" "}
                    <span style={{ color: "#000000" }}>{selectedTime}</span>
                  </p>
                </Col>
              </Row>

              {/* Decoration Details */}
              <Row className="mt-4">
                <Col sm={6}>
                  <h5 className="custom-summary-subtitle">
                    Decoration Details
                  </h5>
                  <p>
                    <strong style={{ color: "#007bff" }}>Type:</strong>{" "}
                    <span style={{ color: "#000000" }}>
                      {selectedDecorationType}
                    </span>
                  </p>
                  <p>
                    <strong style={{ color: "#007bff" }}>
                      Occasions Person Name:
                    </strong>{" "}
                    <span style={{ color: "#000000" }}>
                      {selectedDecorationDetails}
                    </span>
                  </p>
                  <br />
                </Col>
                {selectedTheatreType !== "BASIC" ? (
                  <Col sm={6}>
                    <h5 className="custom-summary-subtitle">Cake Details</h5>
                    <p>
                      <strong style={{ color: "#007bff" }}>Cake:</strong>{" "}
                      <span style={{ color: "#000000" }}>{selectedCake}</span>
                    </p>
                    <p>
                      <strong style={{ color: "#007bff" }}>
                        Name on Cake:
                      </strong>{" "}
                      <span style={{ color: "#000000" }}>{nameonCake}</span>
                    </p>
                  </Col>
                ) : null}
              </Row>

              {/* Optional Gifts */}
              <Row className="mt-4">
                <Col sm={6}>
                  <h5 className="custom-summary-subtitle">Optional Gifts</h5>
                  <div className="gift-options">
                    <label>
                      <input
                        type="checkbox"
                        checked={roseSelected}
                        onChange={() => handleOptionalGifts("rose")}
                      />{" "}
                      <strong style={{ color: "#007bff" }}>Rose Bouquet</strong>{" "}
                      (+₹299)
                      <img
                        src={bouquet}
                        alt="Rose Bouquet"
                        className="gift-image"
                      />
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        checked={teddySelected}
                        onChange={() => handleOptionalGifts("teddy")}
                      />{" "}
                      <strong style={{ color: "#007bff" }}>
                        Small Teddy Bear
                      </strong>{" "}
                      (+₹299)
                      <img
                        src={teddycover}
                        alt="Small Teddy Bear"
                        className="gift-image"
                      />
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        checked={heartPillowSelected}
                        onChange={() => handleOptionalGifts("heartPillow")}
                      />{" "}
                      <strong style={{ color: "#007bff" }}>
                        Small Heart Pillow
                      </strong>{" "}
                      (+₹199)
                      <img
                        src={pillow}
                        alt="Small Heart Pillow"
                        className="gift-image"
                      />
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        checked={catSmallSelected}
                        onChange={() => handleOptionalGifts("cat")}
                      />{" "}
                      <strong style={{ color: "#007bff" }}>Small Cat</strong>{" "}
                      (+₹299)
                      <img
                        src={catcover}
                        alt="Small Cat"
                        className="gift-image"
                      />
                    </label>
                  </div>
                  <br />
                </Col>
                <Col sm={6}>
                  {localStorage.getItem("noofextraAdults") == 0 &&
                  roseSelected === false &&
                  teddySelected === false &&
                  heartPillowSelected === false &&
                  catSmallSelected === false &&
                  ledName === "NA" &&
                  ledNumber === "NA" ? (
                    ""
                  ) : (
                    <>
                      <h5 className="custom-summary-subtitle">
                        Other Details :
                      </h5>
                      {localStorage.getItem("noofextraAdults") > 0 && (
                        <p>
                          <strong style={{ color: "#007bff" }}>
                            No Of Extra Adult:
                          </strong>{" "}
                          <span style={{ color: "#000000" }}>
                            {localStorage.getItem("noofextraAdults")}
                          </span>
                        </p>
                      )}

                      <b>
                        <p>
                          {roseSelected === false &&
                          teddySelected === false &&
                          heartPillowSelected === false &&
                          catSmallSelected === false ? null : (
                            <>
                              <span style={{ color: "#007bff" }}>
                                Gift Selected :
                              </span>
                            </>
                          )}

                          {roseSelected === true ? "Rose Bouquet," : null}
                          {teddySelected === true ? "Teddy Bear," : null}
                          {heartPillowSelected === true
                            ? "Heart Pillow,"
                            : null}
                          {catSmallSelected === true ? "Small Cat" : null}
                        </p>
                      </b>
                      {ledName === "NA" ? null : (
                        <>
                          <p>
                            <strong style={{ color: "#007bff" }}>
                              Led Name:
                            </strong>{" "}
                            <span style={{ color: "#000000" }}>{ledName}</span>
                          </p>
                        </>
                      )}
                      {ledNumber === "NA" ? null : (
                        <>
                          <p>
                            <strong style={{ color: "#007bff" }}>
                              Led Number:
                            </strong>{" "}
                            <span style={{ color: "#000000" }}>
                              {ledNumber}
                            </span>
                          </p>
                        </>
                      )}
                      <br />
                    </>
                  )}
                </Col>
                <Col sm={6}>
                  <h5 className="custom-summary-subtitle">Payment Summary</h5>
                  <p>
                    <strong style={{ color: "#007bff" }}>Package Price:</strong>{" "}
                    <span
                      style={{ color: "#000000" }}
                    >{`₹${selectedTheatrePrice}`}</span>
                  </p>
                  {localStorage.getItem("totalAdultPrice") > 0 && (
                    <p>
                      <strong style={{ color: "#007bff" }}>
                        Total Adult Price:
                      </strong>{" "}
                      <span
                        style={{ color: "#000000" }}
                      >{`₹${localStorage.getItem("totalAdultPrice")}`}</span>
                    </p>
                  )}
                  {selectedCakePrice == 0 ? null : (
                    <>
                      <p>
                        <strong style={{ color: "#007bff" }}>
                          Selected Cake Price:
                        </strong>{" "}
                        <span
                          style={{ color: "#000000" }}
                        >{`₹${selectedCakePrice}`}</span>
                      </p>
                    </>
                  )}
                  {ledNamePrice == "NA" ? null : (
                    <>
                      <p>
                        <strong style={{ color: "#007bff" }}>
                          Led Name Price:
                        </strong>{" "}
                        <span
                          style={{ color: "#000000" }}
                        >{`₹${ledNamePrice}`}</span>
                      </p>
                    </>
                  )}
                  {ledNumberPrice == "NA" ? null : (
                    <>
                      <p>
                        <strong style={{ color: "#007bff" }}>
                          Led Number Price:
                        </strong>{" "}
                        <span
                          style={{ color: "#000000" }}
                        >{`₹${ledNumberPrice}`}</span>
                      </p>
                    </>
                  )}

                  {/* Optional Gifts Price Calculation */}
                  {(roseSelected ||
                    teddySelected ||
                    heartPillowSelected ||
                    catSmallSelected) && (
                    <p>
                      <strong style={{ color: "#007bff" }}>
                        Total Optional Gifts Price:
                      </strong>{" "}
                      <span style={{ color: "#000000" }}>
                        ₹
                        {[
                          roseSelected ? 299 : 0,
                          teddySelected ? 299 : 0,
                          heartPillowSelected ? 199 : 0,
                          catSmallSelected ? 299 : 0,
                        ].reduce((acc, curr) => acc + curr, 0)}
                      </span>
                    </p>
                  )}

                  <p>
                    <strong style={{ color: "#007bff" }}>Total Amount: </strong>{" "}
                    <span style={{ color: "#000000" }}>
                      ₹{`${calculateTotal()}`}{" "}
                    </span>
                  </p>
                  <p>
                    <strong style={{ color: "#007bff" }}>
                      Advance Amount:
                    </strong>{" "}
                    <span style={{ color: "#000000" }}>₹999</span>
                  </p>
                  {copounValue > 0 && (
                    <p>
                      <strong style={{ color: "#007bff" }}>
                        Coupon Value:
                      </strong>{" "}
                      <span
                        style={{ color: "#000000" }}
                      >{`₹${copounValue}`}</span>
                    </p>
                  )}
                  <p>
                    <strong style={{ color: "#007bff" }}>
                      Remaining Amount:
                    </strong>{" "}
                    <span style={{ color: "#000000" }}>
                      {`₹${calculateTotal() - 999 - copounValue}`}
                    </span>
                  </p>

                  <br />
                </Col>
                {selectedTheatreType !== "BASIC" ? (
                  <>
                    <Col sm={6}>
                      <h5 className="custom-summary-subtitle">
                        Coupon Details
                      </h5>
                      <input
                        type="text"
                        value={copoun}
                        onChange={(e) => setCopoun(e.target.value)}
                        className="form-control coupon-input"
                        placeholder="Enter Coupon Code"
                      />
                      <Button
                        onClick={handleCheckCopounCode}
                        className="apply-code-btn"
                      >
                        Apply Code!
                      </Button>
                    </Col>
                  </>
                ) : null}
              </Row>

              <Col sm={12}>
                {msg && (
                  <p
                    className={`coupon-message ${
                      msg.message.includes("Hurrah") ? "success" : "error"
                    }`}
                  >
                    <b
                      style={{
                        fontSize: "15px",
                      }}
                    >
                      {msg.message === "Hurrah!! Coupon Applied!"
                        ? `${msg.message} - ₹${copounValue}`
                        : `${msg.message}`}
                    </b>
                  </p>
                )}
              </Col>

              {/* Add the notes below the Payment Summary */}
              <div style={{ marginTop: "20px" }}>
                <div
                  style={{
                    backgroundColor: "#f0f8ff",
                    border: "1px solid #007bff",
                    padding: "10px",
                    borderRadius: "5px",
                    marginBottom: "10px",
                  }}
                >
                  <p style={{ margin: 0 }}>
                    Note: We collect an advance amount of ₹999 for the
                    reservation of a slot in our theater. The remaining amount
                    is to be paid at the theater on the day of booking.
                  </p>
                </div>
                {/* <div
                  style={{
                    backgroundColor: "#ffebee",
                    border: "1px solid #f44336",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <p style={{ margin: 0 }}>
                    Dear Valued Customer, We are currently experiencing some
                    issues with our payment gateway. Please bear with us as we
                    work to resolve this matter promptly. Your patience and
                    understanding are greatly appreciated. Thank you for your
                    cooperation.
                  </p>
                </div> */}
              </div>
            </Card.Body>

            <Card.Footer className="text-center custom-summary-footer">
              <div className="footer-buttons">
                <Button variant="info" onClick={() => setShowTermsModal(true)}>
                  Terms and Conditions
                </Button>
                <Button variant="info" onClick={() => setShowRefundModal(true)}>
                  Refund Policy
                </Button>
              </div>

              <div className="checkbox-and-text">
                <input
                  type="checkbox"
                  checked={agreeToTerms}
                  onChange={handleCheckboxChange}
                  style={{ marginRight: "10px" }}
                />
                <label htmlFor="termsCheckbox">
                  You are accepting all Terms and Conditions and the refund
                  policy.
                </label>
              </div>
              <Button
                variant="secondary"
                onClick={() =>
                  selectedTheatreType === "BASIC"
                    ? navigate("/decoration")
                    : navigate("/cakedetails")
                }
                className="back-button"
              >
                BACK
              </Button>
              <Button
                variant="primary"
                onClick={() => proceedPay()}
                disabled={!agreeToTerms} // Disable the button if agreeToTerms is false
                className="confirm-button"
              >
                Confirm Book
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>

      {/* Terms and Conditions Modal */}
      {showTermsModal && (
        <div className="modalOverlay">
          <div className="modalContent">
            <button onClick={handleCloseTermsModal} className="closeButton">
              &times;
            </button>
            <h4>Terms and Conditions</h4>
            <ul>
              <li>
                Customers are not allowed to move or rearrange any properties or
                goods set up by MiniFlicks, maintaining the integrity of the
                theatre setup.
              </li>
              <li>
                Customers are not allowed to bring outside food, spark candles,
                and snow sprays into the theatre, ensuring cleanliness and
                compliance with facility regulations.
              </li>
              <li>
                Customers should not make adjustments or modifications to the
                setup of projector, screen, laptop and sound system. If any
                changes are required, we will do it from our end.
              </li>
              <li>
                Individuals below 18 years of age cannot book the theater. Legal
                guardians can book the theater and bring their minor ward(s)
                along with them.
              </li>
              <li>
                Aadhar card is mandatory of couples while check-in the Theatre.
              </li>
              <li>
                Customers are not allowed to smoke, consume alcoholic beverages
                or any illegal substance inside the theater. Appropriate action
                would be taken against such customers.
              </li>
              <li>
                MiniFlicks does not provide content for streaming; customers
                must use their own accounts, and necessary facilities will be
                provided outlining the customer's responsibility for their
                streaming content.
              </li>
              <li>
                MiniFlicks reserves the right to cancel bookings due to
                unforeseen circumstances, and customers will receive a refund
                within 5-7 working days, providing transparency and assurance in
                the event of unforeseen changes.
              </li>
              <li>
                MiniFlicks does not take responsibility for any damage, theft,
                or loss of vehicles parked on the premises. Customers are
                advised to park their vehicles at their own risk, clarifying the
                responsibility for vehicle security.
              </li>
            </ul>
          </div>
        </div>
      )}

      {showRefundModal && (
        <div className="modalOverlay">
          <div className="modalContent">
            <button onClick={handleCloseRefundModal} className="closeButton">
              &times;
            </button>
            <h4>Refund Policy</h4>
            <ul>
              <li>
                Our team collects the Advance amount of 999/- for reservation of
                time slot.
              </li>
              <li>
                This amount is fully refundable (except for convenience charges
                of payment gateway, if any).
              </li>
              <li>
                Customers must inform us about their cancellation or reschedule
                before 72 hours of their booking slot. If they inform us, we
                will initiate a refund within 24 hours and take a maximum of 5-7
                days to be completed.
              </li>
              <li>
                MiniFlicks Private Theatre does not offer refunds for
                cancellations made less than 72 hours in advance of the
                scheduled screening time.
              </li>
              <li>
                No Refunds for bookings scheduled on Friday, Saturday, or Sunday
                slots.
              </li>
              <li>
                Customers must inform us about their cancellation or reschedule
                before 72 hours of their booking slot. If they inform us, we
                will initiate a refund within 24 hours and take a maximum of 5-7
                days to be completed.
              </li>
              <li>
                Also, there will be no refunds in case of rescheduled bookings.
              </li>
              <li>
                MiniFlicks understands that plans can change, and they aim to
                accommodate their customers to the best of their ability. This
                refund policy reflects their commitment to providing a positive
                and flexible experience for those who book private theatre
                screenings.
              </li>
            </ul>
          </div>
        </div>
      )}
    </Container>
  );
};

export default SummaryModel;
