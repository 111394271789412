import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "../../css/payment.success.css";

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
  const transactionId = searchParams.get("id");
  const [message, setMessage] = useState("Successful Payment");
  const selectedDate = localStorage.getItem("selectedDate");
  const selectedTime = localStorage.getItem("selectedTime");
  const selectedTheatre = localStorage.getItem("selectedTheatre");
  const selectedTheatreType = localStorage.getItem("selectedTheatreType");
  const selectedTheatrePrice = Number(
    localStorage.getItem("selectedTheatrePrice")
  );
  const username = localStorage.getItem("username");
  const whatsappNo = localStorage.getItem("whatsappNo");
  const email = localStorage.getItem("email");
  const selectedCake = localStorage.getItem("selectedCake");
  const selectedCakePrice = localStorage.getItem("selectedCakePrice");
  const extraAdults = localStorage.getItem("x") || 0;
  const totalAdultPrice = Number(localStorage.getItem("totalAdultPrice") || 0);
  const couponCode = localStorage.getItem("copounCode");
  const nameonCake = localStorage.getItem("nameonCake");
  const totalAmount = Number(localStorage.getItem("totalAmount"));
  const remainingAmount = Number(localStorage.getItem("remainingAmount"));
  const selectedDecorationType = localStorage.getItem("selectedDecorationType");
  const selectedDecorationDetails = localStorage.getItem(
    "selectedDecorationDetails"
  );
  const specialAddons = JSON.parse(localStorage.getItem("specialAddons"));
  const specialAddonsValue = JSON.parse(
    localStorage.getItem("specialAddonsValue")
  );
  const selectedLedNameDecoration =
    specialAddons.ledName !== "NA" ? "Yes" : "No";
  const selectedLedNumberDecoration =
    specialAddons.ledNumber !== "NA" ? "Yes" : "No";
  const selectedLedNumberDecorationValue =
    specialAddonsValue.ledNumberValue || "NA";
  const selectedLedNameDecorationValue =
    specialAddonsValue.ledNameValue || "NA";
  const rosePathDesgin = localStorage.getItem("rosePathDesign" || "NA");
  const awsProdPublicDomain = process.env.REACT_APP_AWS_EC2_DOMAIN;
  const localhost = process.env.REACT_APP_LOCALHOST;
  let optionalGifts = JSON.parse(localStorage.getItem("optionalGifts"));

  const navigate = useNavigate();
  const handleHome = () => {
    navigate("/");
  };

  const checkAndSaveTransactionDetails = async () => {
    try {
      // const checkResponse = await axios.get(`${localhost}/trnxsaving/check/${transactionId}`);
      const checkResponse = await axios.get(
        `${awsProdPublicDomain}/trnxsaving/check/${transactionId}`
      );
      if (!checkResponse.data.exists) {
        const transactionDetails = {
          transactionId,
          selectedDate,
          selectedTime,
          selectedTheatre,
          selectedTheatreType,
          selectedTheatrePrice,
          selectedDecorationType,
          selectedDecorationDetails,
          selectedLedNameDecoration,
          selectedLedNameDecorationValue,
          selectedLedNumberDecoration,
          selectedLedNumberDecorationValue,
          rosePathDesgin,
          couponCode,
          username,
          email,
          whatsappNo,
          nameonCake,
          selectedCake,
          selectedCakePrice,
          extraAdults,
          totalAdultPrice,
          totalAmount,
          remainingAmount: remainingAmount || "Remaining Amount Missing",
        };

        // const response = await axios.post(
        //   `${localhost}/trnxsaving`,
        //   transactionDetails
        // );
        const response = await axios.post(
          `${awsProdPublicDomain}/trnxsaving`,
          transactionDetails,
          optionalGifts
        );
        console.log("Transaction saved!", response.data);

        // Send notifications after saving transaction details
        await handleSendingNotificationsToUser();

        // Set flag in localStorage to prevent re-saving on page refresh
        localStorage.setItem("transactionSaved", true);
        // localStorage.clear()
      } else {
        console.log("Transaction ID already exists, no action taken.");
      }
    } catch (err) {
      console.error("Error in transaction processing:", err);
    }
  };

  const handleSendingNotificationsToUser = async () => {
    try {
      // await axios.post(`${localhost}/twilio/notifyinguser`, {
      //   transactionId,
      // });
      await axios.post(`${awsProdPublicDomain}/twilio/notifyinguser`, {
        transactionId,
      });
      console.log("Notifications sent successfully.");
    } catch (error) {
      console.error("Error sending notifications:", error);
    }
  };

  useEffect(() => {
    if (transactionId) {
      // Always check if the transaction exists, even if the "transactionSaved" flag is set
      checkAndSaveTransactionDetails();
    } else {
      console.error("Transaction ID is missing!");
    }
  }, [transactionId]);

  // Check if any optional gift is selected
  const isAnyGiftSelected =
    optionalGifts.rose !== "NA" ||
    optionalGifts.teddy !== "NA" ||
    optionalGifts.heartPillow !== "NA" ||
    optionalGifts.cat !== "NA";

  return transactionId ? (
    <>
      <div className="payment-success-container">
        <div className="payment-success-message">{message}</div>
        <div className="payment-success-card">
          <h2>Booking Details</h2>
          <div className="payment-success-detail">
            <span className="payment-success-label">Transaction Id: </span>
            {transactionId}
          </div>
          <div className="payment-success-detail">
            <span className="payment-success-label">Name: </span>
            {username}
          </div>
          <div className="payment-success-detail">
            <span className="payment-success-label">Email: </span>
            {email}
          </div>
          <div className="payment-success-detail">
            <span className="payment-success-label">WhatsApp No: </span>
            {whatsappNo}
          </div>
          <div className="payment-success-detail">
            <span className="payment-success-label">Selected Date: </span>
            {selectedDate}
          </div>
          <div className="payment-success-detail">
            <span className="payment-success-label">Selected Time: </span>
            {selectedTime}
          </div>
          <div className="payment-success-detail">
            <span className="payment-success-label">Selected Theatre: </span>
            {selectedTheatre}
          </div>
          <div className="payment-success-detail">
            <span className="payment-success-label">Package Type: </span>
            {selectedTheatreType}
          </div>
          <div className="payment-success-detail">
            <span className="payment-success-label">Package Price: </span>₹
            {selectedTheatrePrice}
          </div>
          {extraAdults > 0 && (
            <div className="payment-success-detail">
              <span className="payment-success-label">
                No of Extra Adults:{" "}
              </span>
              {extraAdults}
            </div>
          )}
          {totalAdultPrice > 0 && (
            <div className="payment-success-detail">
              <span className="payment-success-label">
                Total Extra Adult Price:{" "}
              </span>
              ₹{totalAdultPrice}
            </div>
          )}

          <div className="payment-success-detail">
            <span className="payment-success-label">Decoration: </span>
            {selectedDecorationType}
          </div>
          <div className="payment-success-detail">
            <span className="payment-success-label">Occasion Details: </span>
            {selectedDecorationDetails}
          </div>
          {selectedLedNameDecoration ? (
            <div className="payment-success-detail">
              <span className="payment-success-label">Led Name: </span>
              {selectedLedNameDecoration}
            </div>
          ) : null}

          {selectedLedNameDecorationValue ? (
            <div className="payment-success-detail">
              <span className="payment-success-label">Led Name Value: </span>
              {selectedLedNameDecorationValue}
            </div>
          ) : null}
          {/* Optional Gifts Section */}
          {isAnyGiftSelected && (
            <div className="payment-success-detail">
              <span className="payment-success-label">Optional Gifts:</span>
              {optionalGifts.rose !== "NA" && "Rose Bouquet, "}
              {optionalGifts.teddy !== "NA" && "Teddy, "}
              {optionalGifts.heartPillow !== "NA" && "Heart Pillow, "}
              {optionalGifts.cat !== "NA" && "Cat"}
            </div>
          )}

          {rosePathDesgin !== "NA" && (
            <div className="payment-success-detail">
              <span className="payment-success-label">Rose Path :</span>
              {rosePathDesgin}
            </div>
          )}

          {selectedTheatreType !== "BASIC" && (
            <>
              <div className="payment-success-detail">
                <span className="payment-success-label">Selected Cake: </span>
                {selectedCake}
              </div>
              <div className="payment-success-detail">
                <span className="payment-success-label">Name on Cake: </span>
                {nameonCake}
              </div>
              {selectedCakePrice > 0 && (
                <div className="payment-success-detail">
                  <span className="payment-success-label">Cake Price: </span>₹
                  {selectedCakePrice}
                </div>
              )}
            </>
          )}

          <div className="payment-success-detail">
            <span className="payment-success-label">Total Amount: </span>₹
            {totalAmount}
          </div>
          <div className="payment-success-detail">
            <span className="payment-success-label">Advance Amount Paid: </span>
            ₹{999}
          </div>
         {couponCode !== "NA" ? (
            <div className="payment-success-detail">
              <span className="payment-success-label">Coupon Value: </span>₹
              {couponCode}
            </div>
          ) : null}

          <div className="payment-success-detail">
            <span className="payment-success-label">Remaining Amount: </span>₹
            {remainingAmount}
          </div>
        </div>
        <button className="payment-success-button" onClick={handleHome}>
          Back to Home!
        </button>
      </div>
    </>
  ) : (
    <div className="payment-success-error">No Transaction Id found!</div>
  );
};

export default PaymentSuccess;
