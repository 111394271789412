import React ,{useEffect}from "react";
import "../../css/privacy.policy.css";
import { FaLock, FaRegCircle, FaRegTimesCircle } from "react-icons/fa";

const PrivacyPolicy = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  })
  return (
    <div className="privacy-policy-container">
      <h2 className="privacy-policy-title">Privacy Policy</h2>
      <ul className="privacy-policy-list">
        <li className="privacy-policy-item" data-wow-delay="0.1s">
          <FaLock className="policy-icon" />
          At MiniFlicks, the privacy of our visitors is a main priority. This document outlines the types of information collected and how it's used.
        </li>
        <li className="privacy-policy-item" data-wow-delay="0.2s">
          <FaRegCircle className="policy-icon" />
          Personal information is collected with clear explanation at the point of collection, which may include details like your name, email, and phone number.
        </li>
        <li className="privacy-policy-item" data-wow-delay="0.3s">
          <FaRegCircle className="policy-icon" />
          We use your information to provide services, process transactions, communicate updates, and improve our offerings.
        </li>
        <li className="privacy-policy-item" data-wow-delay="0.4s">
          <FaRegCircle className="policy-icon" />
          We take reasonable measures to protect your information but acknowledge that no method is completely secure.
        </li>
        <li className="privacy-policy-item" data-wow-delay="0.5s">
          <FaRegTimesCircle className="policy-icon" />
          Information may be shared with service providers, partners, or for legal reasons, all within the bounds of your consent.
        </li>
        <li className="privacy-policy-item" data-wow-delay="0.6s">
          <FaRegCircle className="policy-icon" />
          You have rights to access, update, or request deletion of your personal information, as per legal standards.
        </li>
        <li className="privacy-policy-item" data-wow-delay="0.7s">
          <FaRegTimesCircle className="policy-icon" />
          Our website is not intended for children under 18 without parental consent.
        </li>
        <li className="privacy-policy-item" data-wow-delay="0.8s">
          <FaLock className="policy-icon" />
          Changes to our privacy policy will be communicated through updates on our website.
        </li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;