import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "../css/theatre.gallery.css";

const TheatreGallery = () => {
  const [images, setImages] = useState([]);
  const [enlargedImage, setEnlargedImage] = useState(null);
  const { folderName } = useParams();
  const awsProdPublicDomain = process.env.REACT_APP_AWS_EC2_DOMAIN;
  const localhost = process.env.REACT_APP_LOCALHOST;

  useEffect(() => {
    window.scrollTo(0, 0);
    // <---Prod --->
    axios
      .get(`${awsProdPublicDomain}/admin/img-get?folderName=${folderName}`)
      .then((response) => {
        console.log(response?.data?.data);
        setImages(response?.data?.data);
      })
      .catch((err) => console.log(err));

    // <---Local --->

    // axios
    //   .get(`${localhost}/admin/img-get?folderName=${folderName}`)

    // .then((response) => {
    //   console.log(response?.data?.data);
    //   setImages(response?.data?.data);
    // })
    // .catch((err) => console.log(err));
  }, [folderName]);

  const handleImageClick = (img) => {
    setEnlargedImage(img);
  };

  const handleCloseEnlarged = () => {
    setEnlargedImage(null);
  };

  if (images.length === 0) {
    return (
      <div className="theatre-gallery">
        <p className="no-images">No Images for the selected folder!</p>
      </div>
    );
  }

  return (
    <div className="theatre-gallery" onClick={handleCloseEnlarged}>
      <h2>
        {folderName.charAt(0).toUpperCase() + folderName.slice(1)} Gallery
      </h2>
      <div className="image-grid">
        {images?.map((img, index) => (
          <div
            key={index}
            className="image-container"
            onClick={(e) => {
              e.stopPropagation();
              handleImageClick(img);
            }}
          >
            <img src={img.imgurl} alt={img.key} />
          </div>
        ))}
      </div>
      {enlargedImage && (
        <div className="enlarged" onClick={handleCloseEnlarged}>
          <img src={enlargedImage.imgurl} alt={enlargedImage.key} />
        </div>
      )}
    </div>
  );
};

export default TheatreGallery;
