import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../../../css/admin.sideslot.css";

const AdminOrders = () => {
  const [totalOrderDetailsFromAdmin, setTotalOrderDetailsFromAdmin] = useState(
    []
  );
  const awsProdPublicDomain = process.env.REACT_APP_AWS_EC2_DOMAIN;
  const localhost = process.env.REACT_APP_LOCALHOST;
  const accessToken = localStorage.getItem("admin-accessToken");
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate("/admin");
  };
  useEffect(() => {
    // <---Prod --->
    axios
      .get(`${awsProdPublicDomain}/admin/slotfrom-admin`)
      .then((data) => {
        setTotalOrderDetailsFromAdmin(data.data.message);
      })
    .catch((err) => console.log(err));
    // < --- localhost --->

    // axios
    //   .get(`${localhost}/admin/slotfrom-admin`)
    //   .then((data) => {
    //     setTotalOrderDetailsFromAdmin(data.data.message);
    //   })
    //   .catch((err) => console.log(err));
  }, []);

  const handleDeleteOrder = async (deletedOrderId) => {
    try {
      await axios
        .delete(`${awsProdPublicDomain}/admin/admin-order/${deletedOrderId}`)
        .then((res) => {
          setTotalOrderDetailsFromAdmin(
            totalOrderDetailsFromAdmin.filter(
              (order) => order._id !== deletedOrderId
            )
          );
        });

      // await axios
      //   .delete(`${localhost}/admin/admin-order/${deletedOrderId}`)
      //   .then((res) => {
      //     setTotalOrderDetailsFromAdmin(
      //       totalOrderDetailsFromAdmin.filter(
      //         (order) => order._id !== deletedOrderId
      //       )
      //     );
      //   });
    } catch (error) {
      console.error("There was an error deleting the order!", error);
    }
  };
  return accessToken ? (
    <>
      <div className="admin-orders-body">
        <div className="admin-orders-container admin-orders-mt-5 admin-orders-px-2">
          <div className="admin-orders-mb-2 d-flex justify-content-between align-items-center">
            <div className="admin-orders-px-2"></div>
          </div>
          <div className="admin-orders-table-responsive">
            <table className="admin-orders-table admin-orders-table-borderless">
              <thead>
                <tr className="admin-orders-bg-light">
                  <th scope="col" width="30%">
                    Status
                  </th>
                  <th scope="col" width="7%">
                    User
                  </th>
                  <th scope="col" width="5%">
                    WhatsAppNo
                  </th>
                  <th scope="col" width="7%">
                    Selected Date
                  </th>
                  <th scope="col" width="23%">
                    Selected Time
                  </th>
                  <th scope="col" width="7%">
                    Selected Theater
                  </th>
                  <th scope="col" width="5%">
                    Selected Package
                  </th>
                  <th scope="col" width="5%">
                  Occasions
                  </th>
                  <th scope="col" width="5%">
                  Occasions Details
                  </th>
                  <th scope="col" width="3%">
                    Led
                  </th>
                  <th scope="col" width="5%">
                    Led Details
                  </th>
                  <th scope="col" width="3%">
                    Led Num
                  </th>
                  <th scope="col" width="5%">
                    Led Num Details
                  </th>
                  <th scope="col" width="2%">
                    Extra Adults
                  </th>
                  <th scope="col" width="3%">
                    Cake Selected
                  </th>
                  <th scope="col" width="2%">
                    Name on Cake
                  </th>
                  <th scope="col" width="2%">
                    Cake Price
                  </th>
                  <th scope="col" width="2%">
                    Extra Adults Price
                  </th>
                  <th scope="col" width="5%">
                    Package Price
                  </th>
                  <th scope="col" width="5%">
                    Total Amount
                  </th>
                  <th scope="col" width="5%">
                    Delete Order
                  </th>
                </tr>
              </thead>
              <tbody>
                {totalOrderDetailsFromAdmin.length > 0 ? (
                  totalOrderDetailsFromAdmin.map((orderDetails) => {
                    console.log(orderDetails);
                    return (
                      <tr key={orderDetails._id}>
                        <td>
                          <i
                            className={`fa ${
                              orderDetails?.status === "Paid"
                                ? "fa-check-circle admin-orders-green"
                                : "fa-dot-circle text-danger"
                            }`}
                          ></i>
                          <span className="ms-1">{orderDetails?.status}</span>
                        </td>
                        <td>{orderDetails?.username}</td>
                        <td>{orderDetails?.whatsappNo}</td>
                        <td>{orderDetails?.selectedDate}</td>
                        <td>{orderDetails?.selectedTime}</td>
                        <td>{orderDetails?.selectedTheatre}</td>
                        <td>{orderDetails?.selectedTheatreType}</td>
                        <td>{orderDetails?.selectedDecorationType}</td>
                        <td>{orderDetails?.selectedDecorationDetails}</td>
                        <td>
                          {orderDetails?.selectedLedNameDecoration === "NA"
                            ? "No"
                            : orderDetails?.selectedLedNameDecoration}
                        </td>
                        <td>
                          {orderDetails?.selectedLedNameDecorationValue === "NA"
                            ? "No"
                            : orderDetails?.selectedLedNameDecorationValue}
                        </td>
                        <td>
                          {orderDetails?.selectedLedNumberDecoration === "NA"
                            ? "No"
                            : orderDetails?.selectedLedNumberDecoration}
                        </td>
                        <td>
                          {orderDetails?.selectedLedNumberDecorationValue ===
                          "NA"
                            ? "No"
                            : orderDetails?.selectedLedNumberDecorationValue}
                        </td>
                        <td>{orderDetails?.extraAdults}</td>
                        <td>
                          {orderDetails?.selectedCake === "NA"
                            ? "No"
                            : orderDetails?.selectedCake}
                        </td>
                        <td>
                          {orderDetails?.nameonCake === "NA"
                            ? "No"
                            : orderDetails?.nameonCake}
                        </td>
                        <td>
                          ₹
                          {orderDetails?.selectedCakePrice === "0"
                            ? "0"
                            : orderDetails?.selectedCakePrice}
                        </td>
                        <td>₹{orderDetails?.totalAdultPrice}</td>
                        <td>₹{orderDetails?.selectedTheatrePrice}</td>
                        <td>₹{orderDetails?.totalAmount}</td>

                        <td>
                          <button
                            onClick={() => handleDeleteOrder(orderDetails?._id)}
                            className="customer-booked-delete-button"
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="12" className="admin-orders-no-data">
                      No Data Fetched!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      `No Access Token Found `<button onClick={handleLogin}>Login</button>
    </>
  );
};

export default AdminOrders;
