import React, { useEffect, useState } from "react";
import familyphoto from "../../cover/familyphoto.jpeg";
import friendsphoto from "../../cover/friendsphoto.jpeg";
export default function About() {
  const urlPath = window.location.pathname;
  const [isHovered, setIsHovered] = useState(false);
  const containerStyle = {
    transition: "transform 0.3s ease-in-out",
    transform: isHovered ? "scale(1.05)" : "scale(1.0)",
  };
  const imageStyle = {
    transition: "transform 0.3s ease-in-out",
    borderRadius: "15px",
  };

  const textStyle = {
    textAlign: "justify",
    animation: "fadeInUp 1s ease-in-out",
    color: "#444",
    animation: "blast 2s ease-in-out infinite",
  };

  const headingStyle = {
    fontFamily: "Jokerman",
    color: "#007bff",
    animation: "fadeIn 1.5s ease-in-out",
    animation: "fadeIn 1.5s ease-in-out infinte",
  };

  const roomTextStyle = {
    fontWeight: "bold",
    animation: "fadeInUp 1.5s ease-in-out",
    color: "#ff6347",
    marginBottom: "0.5rem",
    fontSize: "1.2rem",
  };
  const brandStoryHeadingStyle = {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 600,
    fontSize: "2rem",
    color: "#FEA116",
    animation: "fadeIn 1s ease-in-out",
    textAlign: "center",
    marginBottom: "2rem",
  };
  const brandStoryTextStyle = {
    textAlign: "justify",
    animation: "fadeInUp 1s ease-in-out",
    fontSize: "1rem",
    lineHeight: "1.6",
    color: "#444",
  };
  const highlightStyle = {
    color: "#e74c3c",
    fontWeight: "bold",
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const fadeInScaleKeyframes = `
    @keyframes fadeInScale {
      0% {
        opacity: 0;
        transform: scale(0.8);
      }
      50% {
        opacity: 0.5;
        transform: scale(0.9);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  `;

  const headingStyle1 = {
    color: "#007bff",
    animation: "fadeInScale 5s ease-in-out infinite",
  };

  return (
    <>
      <div className="container-xxl py-5" style={containerStyle}>
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <style>{fadeInScaleKeyframes}</style>
              <h1 className="mb-4" style={headingStyle1}>
                Welcome to{" "}
                <span
                  className="text-primary text-uppercase"
                  style={headingStyle}
                >
                  MiniFlicks
                </span>
              </h1>
              <p className="mb-4" style={textStyle}>
                <span style={highlightStyle}>MiniFlicks Private Theater</span>{" "}
                is a versatile celebration space that caters to a range of
                events, including{" "}
                <span style={{ color: "#1abc9c", fontWeight: "bold" }}>
                  movie screenings
                </span>
                ,{" "}
                <span style={{ color: "#e67e22", fontWeight: "bold" }}>
                  birthday celebrations
                </span>
                ,{" "}
                <span style={{ color: "#9b59b6", fontWeight: "bold" }}>
                  anniversaries
                </span>
                , and{" "}
                <span style={{ color: "#3498db", fontWeight: "bold" }}>
                  proposals
                </span>
                . With its state-of-the-art audiovisual technology and
                customizable ambiance, MiniFlicks provides the perfect setting
                for creating memorable experiences.
              </p>
              <p className="mb-4" style={textStyle}>
                Whether it’s an{" "}
                <span style={{ color: "#f1c40f", fontWeight: "bold" }}>
                  intimate movie night
                </span>
                , a{" "}
                <span style={{ color: "#2ecc71", fontWeight: "bold" }}>
                  milestone birthday celebration
                </span>
                , a{" "}
                <span style={{ color: "#e74c3c", fontWeight: "bold" }}>
                  romantic anniversary
                </span>
                , or a{" "}
                <span style={{ color: "#d35400", fontWeight: "bold" }}>
                  special proposal
                </span>
                , MiniFlicks promises a secluded and upscale environment where
                guests can indulge in tailored and unforgettable entertainment.
              </p>
              <p className="mb-4" style={textStyle}>
                This exceptional space caters to a variety of occasions,
                ensuring that each event is nothing short of extraordinary.
              </p>
            </div>
            <div className="col-lg-6">
              <div className="row g-3">
                <div className="col-6 text-end">
                  <img
                    className="img-fluid rounded wow zoomIn"
                    data-wow-delay="0.1s"
                    src="/assets/img/WhatsApp Image 2024-01-01 at 1.49.52 PM.jpeg"
                    style={{ ...imageStyle, marginTop: "25%" }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.transform = "scale(1.1)")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.transform = "scale(1.0)")
                    }
                    alt="First Image"
                  />
                </div>
                <div className="col-6 text-start">
                  <img
                    className="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.3s"
                    src="/assets/img/WhatsApp Image 2024-01-01 at 1.49.45 PM.jpeg"
                    style={imageStyle}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.transform = "scale(1.1)")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.transform = "scale(1.0)")
                    }
                    alt="Second Image"
                  />
                </div>
                <div className="col-6 text-end">
                  <img
                    className="img-fluid rounded w-50 wow zoomIn"
                    data-wow-delay="0.5s"
                    src={friendsphoto}
                    style={imageStyle}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.transform = "scale(1.1)")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.transform = "scale(1.0)")
                    }
                    alt="Third Image"
                  />
                </div>
                <div className="col-6 text-start">
                  <img
                    className="img-fluid rounded w-100 wow zoomIn"
                    data-wow-delay="0.7s"
                    src={familyphoto}
                    style={imageStyle}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.transform = "scale(1.1)")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.transform = "scale(1.0)")
                    }
                    alt="Fourth Image"
                  />
                </div>
              </div>
            </div>
          </div>
          {urlPath === "/about" && (
            <div className="brand-story-section">
              <br />
              <br />
              <h1 className="mb-4" style={brandStoryHeadingStyle}>
                Our Brand Story
              </h1>
              <p className="mb-6" style={brandStoryTextStyle}>
                In the heart of our bustling city,{" "}
                <b style={{ color: "#e74c3c" }}>
                  Chandra Mouli Reddy Vangumalla, Veeranjaneya Reddy Yagnam,
                  Thej Kumar Manchi, and M Kailash
                </b>
                , a group of passionate movie enthusiasts, envisioned a haven
                where people could celebrate life’s special moments while
                enjoying their favorite films.
              </p>
              <p className="mb-6" style={brandStoryTextStyle}>
                This dream gave birth to{" "}
                <b style={{ color: "#FEA116" }}>MiniFlicks Private Theater</b>{" "}
                in Bangalore, a place where movie lovers could immerse
                themselves in cinematic magic and create unforgettable memories.
                Each theater was meticulously designed to provide an intimate
                and luxurious setting for birthdays, anniversaries, and other
                celebrations.
              </p>
              <p className="mb-6" style={brandStoryTextStyle}>
                As word of this innovative concept spread,{" "}
                <b style={{ color: "#1abc9c" }}>MiniFlicks</b> became a
                sought-after destination for those seeking a unique and
                personalized cinematic experience. The brand’s commitment to
                customer satisfaction and its unwavering passion for cinema set
                MiniFlicks apart, making it a place where every visit was not
                just about watching a movie, but about celebrating life’s
                beautiful moments in a setting that felt uniquely their own.
              </p>
              <p className="mb-6" style={brandStoryTextStyle}>
                Our theaters are not just spaces, but canvases for your
                celebrations. Each detail, from the luxurious seating to the
                state-of-the-art sound system, is curated to enhance your
                experience. Whether you're hosting a small family gathering or a
                grand celebration,{" "}
                <span style={highlightStyle}>MiniFlicks</span> is the perfect
                backdrop for your special moments.
              </p>
              <p className="mb-6" style={brandStoryTextStyle}>
                Join us at{" "}
                <b style={{ color: "#1abc9c" }}>MiniFlicks Private Theater</b>{" "}
                and be a part of a community where movies and memories come
                together. We look forward to making your next event
                unforgettable.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
