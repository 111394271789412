import { Link } from "react-router-dom";
import { footerContact, socialIcons } from "../data/Data";
import logo from "../data/logo.278266b17b97127d79da-removebg-preview.png";
import "../../css/footer.css";

export default function Footer() {
  return (
    <footer className="footer-custom text-light wow fadeIn" data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-4 d-flex align-items-center justify-content-center">
            <div className="footer-section text-center">
              <Link to="/">
                <img src={logo} alt="Logo" className="logo-image mb-3" />
              </Link>
              <p>
                MiniFlicks Private Theater in Bangalore is a versatile celebration space that caters to a range of events, including movie screenings, birthday celebrations, anniversaries, and proposals.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-items-center justify-content-center">
            <div className="footer-section contact-details text-center">
              <h6 className="section-title text-primary text-uppercase mb-4">
                Contact
              </h6>
              {footerContact.map((val, index) => (
                <p className="mb-2" key={index}>
                  {val.icon} {val.name}
                </p>
              ))}
            </div>
          </div>
          <div className="col-lg-4 col-md-12 mb-4 d-flex align-items-center justify-content-center">
            <div className="footer-section social-media text-center">
              <h6 className="section-title text-primary text-uppercase mb-4">
                Follow Us
              </h6>
              <div className="social-icons" style={{ fontSize: "22px" }}>
                {socialIcons.map((val, index) => (
                  <div key={index}>
                    {val.isExternal ? (
                      <a
                        className="social-icon"
                        href={val.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {val.icon}
                      </a>
                    ) : (
                      <Link className="social-icon" to={val.url}>
                        {val.icon}
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-links text-center">
          <Link to="/TnC" className="text-light">
            Terms and Conditions
          </Link>
          <span className="text-light">|</span>
          <Link to="/refund-policy" className="text-light">
            Refund Policy
          </Link>
          <span className="text-light">|</span>
          <Link to="/pp" className="text-light">
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  );
}
