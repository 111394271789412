import React from "react";
import Heading from "../components/common/Heading";
import PaymentSuccess from "../components/payment/PaymentSuccess";

export default function PaymentSuccessPage() {
  return (
    <>
      <Heading heading="Payment" title="Home" subtitle="Payment Success" />
      <PaymentSuccess />
    </>
  );
}
