import React from "react";
import Carousel from "./Carousel";
import About from "./About";
import Services from "./Service";
import Theatre from "./Theatre";
// import WhatsAppButton from "./Whatsapp";

export default function Home() {
  return (
    <>
      <Carousel />
      <About />
      <Theatre />
      <Services />
      {/* <WhatsAppButton/> */}
    </>
  );
}
