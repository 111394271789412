import React, { useEffect, useState } from "react";
import "../css/user.detail.css";
import { useNavigate } from "react-router-dom";

const TheaterBookingForm = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const [username, setUsername] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const selectedTheatre = localStorage.getItem("selectedTheatre");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@(gmail\.com|yahoo\.com|hotmail\.com|outlook\.com|rediffmail\.com)$/i;
    return re.test(String(email).toLowerCase());
  };

  const validatePhoneNumber = (number) => {
    return /^\d{10}$/.test(number);
  };

  const handleUserDetails = (e) => {
    e.preventDefault();

    let formErrors = {};

    if (!username) {
      formErrors.username = "Name is required";
    }
    if (!whatsapp) {
      formErrors.whatsapp = "WhatsApp number is required";
    } else if (!validatePhoneNumber(whatsapp)) {
      formErrors.whatsapp = "WhatsApp number must be exactly 10 digits";
    }
    if (!email) {
      formErrors.email = "Email is required";
    } else if (!validateEmail(email)) {
      formErrors.email = "Invalid email address";
    }

    if (Object.keys(formErrors).length === 0) {
      localStorage.setItem("username", username);
      localStorage.setItem("whatsappNo", whatsapp);
      localStorage.setItem("email", email);
      navigate("/packageselection");
    } else {
      setErrors(formErrors);
    }
  };

  const handlePhoneInput = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setWhatsapp(value);
    }
  };

  return (
    <div className="user-details container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <form className="booking-form" onSubmit={handleUserDetails}>
            <h3 className="form-title">{selectedTheatre} Booking</h3>
            <div className="form-group mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              {errors.username && (
                <small className="text-danger">{errors.username}</small>
              )}
            </div>
            <div className="form-group mb-3">
              <label htmlFor="whatsapp" className="form-label">
                WhatsApp Number
              </label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span className="input-group-text" style={{ marginRight: '5px', padding: '5px', borderRadius: '5px 0 0 5px' }}>+91</span>
                <input
                  type="text"
                  className="form-control"
                  id="whatsapp"
                  value={whatsapp}
                  onChange={handlePhoneInput}
                  style={{ borderRadius: '0 5px 5px 0' }}
                />
              </div>
              {errors.whatsapp && (
                <small className="text-danger">{errors.whatsapp}</small>
              )}
            </div>
            <div className="form-group mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && (
                <small className="text-danger">{errors.email}</small>
              )}
            </div>
            <button type="submit" className="btn btn-primary">
              Next
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TheaterBookingForm;
