import React, { useEffect, useState, useRef } from "react";
import "../css/package.selection.css";
import { useNavigate } from "react-router-dom";
import rosepath1 from "../rosepath/rosepath1.jpg";
import rosepath2 from "../rosepath/rosepath2.jpg";
import rosepath3 from "../rosepath/rosepath3.jpg";
import rosepath4 from "../rosepath/rosepath4.jpg";
import rosepath5 from "../rosepath/rosepath5.jpg";
import rosepath6 from "../rosepath/rosepath6.jpg";
import rosepath7 from "../rosepath/rosepath7.jpg";
import rosepath8 from "../rosepath/rosepath8.jpg";
import rosepath9 from "../rosepath/rosepath9.jpg";
import rosepath10 from "../rosepath/rosepath10.jpg";
import ledLetter from "../cover/ledName.jpg";
import ledNum from "../cover/ledNumber.jpg";
import smokeentry from "../cover/smokeEntry.mp4";
import axios from "axios";

const PackageSelection = () => {
  const addonsRef = useRef(null);
  const [packagePrices, setPackagePrices] = useState({});
  const [selectedTheatreType, setSelectedTheatreType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [ledName, setLedName] = useState(false);
  const [ledNameValue, setLedNameValue] = useState("NA");
  const [ledNumber, setLedNumber] = useState(false);
  const [ledNumberValue, setLedNumberValue] = useState("NA");
  const [isVideoModal, setIsVideoModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const selectedTheatre = localStorage.getItem("selectedTheatre");
  const localhost = process.env.REACT_APP_LOCALHOST;
  const awsProdPublicDomain = process.env.REACT_APP_AWS_EC2_DOMAIN;

  const [ledNameError, setLedNameError] = useState("");
  const [ledNumberError, setLedNumberError] = useState("");
  const navigate = useNavigate();

  const images = [
    { id: 1, url: rosepath1 },
    { id: 2, url: rosepath2 },
    { id: 3, url: rosepath3 },
    { id: 4, url: rosepath4 },
    { id: 5, url: rosepath5 },
    { id: 6, url: rosepath6 },
    { id: 7, url: rosepath7 },
    { id: 8, url: rosepath8 },
    { id: 9, url: rosepath9 },
    { id: 10, url: rosepath10 },
  ];

  const basePrices = {
    ledName: 299,
    ledNumber: 99,
  };

  const calculateLedNamePrice = (name) => {
    if (name && name.length > 0) {
      const extraLettersCount = name.length > 8 ? name.length - 8 : 0;
      return basePrices.ledName + extraLettersCount * 30;
    }
    return basePrices.ledName;
  };

  const handleSpecialAddOns = (addOns) => {
    let specialAddons = JSON.parse(localStorage.getItem("specialAddons")) || { ledName: "NA", ledNumber: "NA" };
    let specialAddonsValue = JSON.parse(localStorage.getItem("specialAddonsValue")) || { ledNameValue: "NA", ledNumberValue: "NA" };

    if (addOns === "ledName") {
      const newState = !ledName;
      specialAddons.ledName = newState ? calculateLedNamePrice(ledNameValue) : "NA";
      setLedName(newState);
      if (!newState) {
        setLedNameValue("NA");
        specialAddonsValue.ledNameValue = "NA";
      } else {
        setLedNameValue("");
      }
    } else if (addOns === "ledNumber") {
      const newState = !ledNumber;
      specialAddons.ledNumber = newState ? basePrices.ledNumber : "NA";
      setLedNumber(newState);
      if (!newState) {
        setLedNumberValue("NA");
        specialAddonsValue.ledNumberValue = "NA";
      } else {
        setLedNumberValue("");
      }
    }
    localStorage.setItem("specialAddons", JSON.stringify(specialAddons));
    localStorage.setItem("specialAddonsValue", JSON.stringify(specialAddonsValue));
  };

  useEffect(() => {
    if (selectedTheatre) {
      window.scrollTo(0, 0);
      axios
        .post(`${awsProdPublicDomain}/admin/packagetype-get`, {
          selectedTheatre,
        })
        .then((res) => {
          setPackagePrices(res.data.data);
        })
        .catch((err) => console.log(err));
    }
  }, [selectedTheatre]);

  const handlePackageSelect = (packageType, packagePrice) => {
    localStorage.setItem("selectedTheatrePrice", packagePrice);
    localStorage.setItem("selectedTheatreType", packageType);
    setSelectedTheatreType(packageType);
    addonsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleImageSelect = (id) => {
    setSelectedImageId(id);
  };

  const handleModalSelect = () => {
    if (selectedImageId !== null) {
      localStorage.setItem("selectedTheatrePrice", packagePrices["ULTRA_PREMIUM"]);
      localStorage.setItem("rosePathDesign", selectedImageId || "NA");
      localStorage.setItem("selectedTheatreType", "ULTRA_PREMIUM");
      navigate("/decoration");
    }
  };

  const handleOpenVideoModal = (url) => {
    setVideoUrl(url);
    setIsModalOpen(true);
    setIsVideoModal(true);
  };

  const handleOpenImageModal = () => {
    setIsModalOpen(true);
    setIsVideoModal(false);
  };

  const closeModal = () => {
    if (!isVideoModal) {
      handleModalSelect();
    }
    setIsModalOpen(false);
    setIsVideoModal(false);
    setVideoUrl("");
  };

  const validateAndStoreAddOns = () => {
    let specialAddons = JSON.parse(localStorage.getItem("specialAddons")) || { ledName: "NA", ledNumber: "NA" };
    let specialAddonsValue = JSON.parse(localStorage.getItem("specialAddonsValue")) || { ledNameValue: "NA", ledNumberValue: "NA" };
    let isValid = true;

    if (ledName && !ledNameValue) {
      setLedNameError("LED Name is required");
      isValid = false;
    } else {
      specialAddons.ledName = ledName ? calculateLedNamePrice(ledNameValue) : "NA";
      specialAddonsValue.ledNameValue = ledName ? ledNameValue : "NA";
      setLedNameError("");
    }

    if (ledNumber && !ledNumberValue) {
      setLedNumberError("LED Number is required");
      isValid = false;
    } else {
      specialAddons.ledNumber = ledNumber ? basePrices.ledNumber : "NA";
      specialAddonsValue.ledNumberValue = ledNumber ? ledNumberValue : "NA";
      setLedNumberError("");
    }

    localStorage.setItem("specialAddons", JSON.stringify(specialAddons));
    localStorage.setItem("specialAddonsValue", JSON.stringify(specialAddonsValue));

    return isValid;
  };

  const handleNext = () => {
    if (validateAndStoreAddOns()) {
      localStorage.setItem("rosePathDesign", selectedImageId || "NA");
      localStorage.setItem("selectedCakePrice", 0);
      localStorage.setItem("selectedCake", "NA");
      localStorage.setItem("nameonCake", "NA");
      navigate("/decoration");
    }
  };

  const packageOrder = ["BASIC", "STANDARD", "PREMIUM", "ULTRA_PREMIUM"];

  const features = [
    selectedTheatre !== "Family Theatre"
      ? { name: "4K Dolby theater ", key: "dolby" }
      : { name: "4K Dolby theater ", key: "dolby" },
    { name: "Decoration", key: "decoration" },
    { name: "Cake", key: "cake" },
    { name: "Smoke Entry", key: "smokeEntry" },
    { name: "Rose Heart on Table", key: "roseHeart" },
    { name: "Rose with Candle Path", key: "candlePath" },
  ];

  const packageFeatures = {
    BASIC: ["dolby", "decoration"],
    STANDARD: ["dolby", "decoration", "cake"],
    PREMIUM: ["dolby", "cake", "smokeEntry", "roseHeart", "decoration"],
    ULTRA_PREMIUM: [
      "dolby",
      "cake",
      "smokeEntry",
      "roseHeart",
      "candlePath",
      "decoration",
    ],
  };

  return (
    <div className="package-selection-unique container mt-5">
      <h2>Select a package</h2>

      <div className="options-unique">
        {packageOrder.map((packageType) => (
          <div key={packageType} className="package-card-wrapper">
            <div
              className={`package-card-unique ${
                selectedTheatreType === packageType ? "selected-unique" : ""
              }`}
            >
              <div className="card-body-unique">
                <h4 className="card-title-unique">{packageType}</h4>
                <h5>₹{packagePrices[packageType]}/-</h5>
                <ul className="card-text-unique">
                  {features
                    .filter((feature) =>
                      packageFeatures[packageType].includes(feature.key)
                    )
                    .map((feature) => (
                      <li key={feature.key} style={{ position: "relative" }}>
                        <span className="text-success-unique">
                          ✓ {feature.name}
                          {feature.key === "candlePath" ? (
                            <button
                              onClick={handleOpenImageModal}
                              className="view-button-unique"
                            >
                              View
                            </button>
                          ) : feature.key === "smokeEntry" ? (
                            <button
                              onClick={() => handleOpenVideoModal(smokeentry)}
                              className="view-button-unique"
                            >
                              View
                            </button>
                          ) : null}
                        </span>
                      </li>
                    ))}
                  {features
                    .filter(
                      (feature) =>
                        !packageFeatures[packageType].includes(feature.key)
                    )
                    .map((feature) => (
                      <li key={feature.key} style={{ position: "relative" }}>
                        <span className="text-danger-unique">
                          ✗ {feature.name}
                        </span>
                      </li>
                    ))}
                </ul>
                <button
                  className={`btn btn-primary mx-2 select-button ${
                    selectedTheatreType === packageType ? "selected" : ""
                  }`}
                  onClick={() =>
                    handlePackageSelect(packageType, packagePrices[packageType])
                  }
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {!selectedTheatreType ? null : (
        <>
          <div className="selected-package-info">
            <b>
              <p>
                Selected Package : {localStorage.getItem("selectedTheatreType")}{" "}
              </p>
            </b>
          </div>
        </>
      )}
      <div ref={addonsRef} className="special-addons-card-unique">
        <b style={{ color: "darkblue" }}>
          Special AddOns (Optional):
          <br />
          <br />
          {ledNameValue.length > 8 ? (
            <>
              <p style={{ color: "green", fontWeight: "light" }}>
                Note : If there are more than eight characters, customers need
                to pay ₹30 for each letter.
              </p>
            </>
          ) : null}
        </b>
        <div className="addon-row-unique">
          <div className="addon-item-unique">
            <label className="addon-option-unique">
              <input
                type="checkbox"
                checked={ledName}
                onChange={() => handleSpecialAddOns("ledName")}
              />{" "}
              LED Name - ₹299/-
            </label>
            <img src={ledLetter} alt="LED Name" className="addon-image-unique" />
            {ledName && (
              <div className="addon-value-unique">
                <input
                  type="text"
                  value={ledNameValue}
                  onChange={(e) => {
                    setLedNameValue(e.target.value);
                    let specialAddons =
                      JSON.parse(localStorage.getItem("specialAddons")) || {};
                    specialAddons.ledName = calculateLedNamePrice(
                      e.target.value
                    );
                    localStorage.setItem(
                      "specialAddons",
                      JSON.stringify(specialAddons)
                    );
                  }}
                  placeholder="Enter LED Name"
                />
                {ledNameError && <p style={{ color: "red" }}>{ledNameError}</p>}
              </div>
            )}
          </div>
          <div className="addon-item-unique">
            <label className="addon-option-unique">
              <input
                type="checkbox"
                checked={ledNumber}
                onChange={() => handleSpecialAddOns("ledNumber")}
              />{" "}
              LED Number - ₹99/-
            </label>
            <img src={ledNum} alt="LED Number" className="addon-image-unique" />
            {ledNumber && (
              <div className="addon-value-unique">
                <input
                  type="text"
                  value={ledNumberValue}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d{0,2}$/.test(value)) {
                      // This regex allows only up to two digits
                      setLedNumberValue(value);
                    }
                  }}
                  placeholder="Enter LED Number"
                />
                {ledNumberError && (
                  <p style={{ color: "red" }}>{ledNumberError}</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {!selectedTheatreType ? null : (
        <>
          <div className="next-button-container">
            <button className="btn btn-primary mx-2" onClick={handleNext}>
              Next
            </button>
          </div>
        </>
      )}

      {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 999,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
              width: "auto",
              maxWidth: "90%",
              width: "auto", // Dynamic width adjustment
              maxWidth: "90%", // Ensure it doesn't exceed the viewport
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxHeight: "80vh",
              overflowY: "auto",
            }}
          >
            {isVideoModal ? (
              <video controls className="modal-video">
                <source src={smokeentry} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <>
                <h3> Rose Path Design Reference </h3>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    justifyContent: "center",
                    marginBottom: "20px",
                  }}
                >
                  {images.map((image) => (
                    <img
                      key={image.id}
                      src={image.url}
                      alt={`Rose Path Design ${image.id}`}
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "cover",
                        borderRadius: "4px",
                        cursor: "pointer",
                        transition: "transform 0.2s",
                        border:
                          selectedImageId === image.id
                            ? "2px solid #007bff"
                            : "none",
                      }}
                      
                      className={`modal-image ${
                        selectedImageId === image.id ? "selected-image" : ""
                      }`}
                    />
                  ))}
                </div>
              </>
            )}
            <button className="btn btn-primary modal-button" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PackageSelection;
