import { useNavigate } from "react-router-dom";
import Couple from "../components/data/Couple1.jpeg";
import Friends from "../components/data/friends1.jpeg";
import Family from "../components/data/family1.jpeg";
import "../css/image.gallery.css";
import { useEffect } from "react";

const ImageGallery = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNavigate = (path) => {
    navigate(path);
  };

  const textStyle = {
    textAlign: "justify",
    animation: "fadeInUp 1s ease-in-out",
  };

  const headingStyle = {
    fontFamily: "Jokerman",
    color: "#007bff",
    animation: "fadeIn 1.5s ease-in-out",
  };

  const brandTitleStyle = {
    fontFamily: "Jokerman",
    color: "#1a1a1a",
    animation: "fadeIn 1.2s ease-in-out",
  };

  const roomTextStyle = {
    fontWeight: "bold",
    animation: "fadeInUp 1.5s ease-in-out",
    color: "#ff6347",
    marginBottom: "0.5rem",
  };

  const brandStoryHeadingStyle = {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 600,
    fontSize: "2rem",
    color: "#FEA116",
    animation: "fadeIn 1s ease-in-out",
    textAlign: "center",
  };

  const brandStoryTextStyle = {
    textAlign: "justify",
    animation: "fadeInUp 1s ease-in-out",
    fontSize: "1.1rem",
    lineHeight: "1.6",
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  };

  const contentStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
  };

  const textContentStyle = {
    flex: "1",
    padding: "0 20px",
  };

  return (
    <>
      <div className="page-container">
        <div className="content-wrap">
          <div className="image-gallery-container">
            <div
              onClick={() => handleNavigate("/gallery/couple")}
              className="image-gallery-card"
            >
              <h3>Couple Theatre</h3>
              <img src={Couple} alt="Couple Theatre" />
            </div>
            <div
              onClick={() => handleNavigate("/gallery/friends")}
              className="image-gallery-card"
            >
              <h3>Friends Theatre</h3>
              <img src={Friends} alt="Friends Theatre" />
            </div>
            <div
              onClick={() => handleNavigate("/gallery/family")}
              className="image-gallery-card"
            >
              <h3>Family Theatre</h3>
              <img src={Family} alt="Family Theatre" />
            </div>
          </div>
          <br />
          <div style={containerStyle}>
            <div style={contentStyle}>
              <div className="text-content mb-4" style={textContentStyle}>
                <h1 className="mb-4 ml-4" style={headingStyle}>
                  MiniFlicks <br />
                  <span
                    className="text-primary text-uppercase"
                    style={headingStyle}
                  >
                    Welcomes You!
                  </span>
                </h1>
                <p className="mb-4 ml-4" style={textStyle}>
                  MiniFlicks Private Theater is a versatile event space designed
                  for a variety of special occasions, such as movie screenings,
                  birthday parties, anniversaries, and proposals. Equipped with
                  state-of-the-art audiovisual technology and customizable
                  ambiance, MiniFlicks ensures a memorable experience for every
                  event.
                </p>
                <p className="mb-4 ml-4" style={textStyle}>
                  Whether you're hosting a cozy movie night, celebrating a
                  milestone birthday, marking a romantic anniversary, or
                  planning a special proposal, MiniFlicks offers a private,
                  upscale environment perfect for unforgettable moments.
                </p>
                <p className="mb-4 ml-4" style={textStyle}>
                  Our venue is tailored to make each occasion unique and
                  extraordinary, providing the ideal setting for your cherished
                  memories.
                </p>
                <span className="mb-4 ml-4" style={roomTextStyle}>
                  Family Theater
                </span>
                <p className="mb-4 ml-4" style={textStyle}>
                  Enjoy a private screening with your family in our cozy family
                  theater, perfect for a fun movie night together.
                </p>
                <span className="mb-4 ml-4" style={roomTextStyle}>
                  Couple Theater
                </span>
                <p className="mb-4 ml-4" style={textStyle}>
                  Our couple theater are designed to offer a romantic and intimate
                  movie experience, creating unforgettable memories for you and
                  your partner.
                </p>
                <span className="mb-4 ml-4" style={roomTextStyle}>
                  Friends Theater
                </span>
                <p className="mb-4 ml-4" style={textStyle}>
                  Gather with friends and enjoy your favorite movies in our
                  friends theater, ideal for group hangouts and celebrations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageGallery;
