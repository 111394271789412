import React, { useState, useEffect } from "react";
import family1 from "../data/family1.jpeg";
import familycover from "../../cover/familycover.jpeg";

const FamilyTheatreCard = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [family1, familycover];

  const cardStyle = {
    maxWidth: "500px", // Increased width for larger card
    margin: "auto",
    border: "1px solid #ddd",
    borderRadius: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    padding: "30px", // Increased padding for a spacious feel
    backgroundColor: "#fff",
    fontFamily: "Arial, sans-serif",
    color: "#333",
    textAlign: "left",
  };

  const headerStyle = {
    textAlign: "center",
    color: "#007bff",
    marginBottom: "25px", // Increased margin for better spacing
    fontSize: "28px", // Increased font size for better readability
    borderBottom: "2px solid #eee",
    paddingBottom: "15px", // Increased padding for a clean look
  };

  const textStyle = {
    fontSize: "18px", // Increased font size for better readability
    lineHeight: "1.8",
    color: "#666",
    display: "flex",
    alignItems: "center",
    marginBottom: "15px", // Increased margin for spacing between items
  };

  const iconStyle = {
    marginRight: "12px", // Slightly increased margin for better alignment
    color: "#333",
  };

  const carouselContainerStyle = {
    position: "relative",
    maxWidth: "100%",
    margin: "auto",
    overflow: "hidden",
    height: "300px", // Increased height for the image container
  };

  const imageStyle = {
    width: "100%",
    height: "100%", // Ensure the image takes up the full height of the container
    borderRadius: "10px",
    objectFit: "cover", // Maintain aspect ratio and cover the container
  };

  const buttonStyle = {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "#fff",
    border: "none",
    padding: "10px",
    cursor: "pointer",
  };

  const prevButtonStyle = {
    ...buttonStyle,
    left: "10px",
  };

  const nextButtonStyle = {
    ...buttonStyle,
    right: "10px",
  };

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [images.length]);

  return (
    <div style={cardStyle}>
      <h2 style={headerStyle}>Theatre Details</h2>

      <div style={carouselContainerStyle}>
        <img
          src={images[currentImageIndex]}
          alt={`Carousel ${currentImageIndex + 1}`}
          style={imageStyle}
        />
        <br />
        <br />
        <button style={prevButtonStyle} onClick={handlePrevClick}>
          &#10094;
        </button>
        <button style={nextButtonStyle} onClick={handleNextClick}>
          &#10095;
        </button>
      </div>
      <br />
      <div style={textStyle}>
        <i className="fas fa-users" style={iconStyle}></i>
        <span>Capacity: 4-12</span>
      </div>
      <div style={textStyle}>
        <i className="fas fa-tv" style={iconStyle}></i>
        <span>Screen Size: 150 Inches 4K</span>
      </div>
      <div style={textStyle}>
        <i className="fas fa-volume-up" style={iconStyle}></i>
        <span>Sound System: Dolby Atmos</span>
      </div>
    </div>
  );
};

export default FamilyTheatreCard;
