import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import CoupleTheatreCard from "./CoupleTheatreCard";
import FamilyTheatreCard from "./FamilyTheatreCard";
import FriendsTheatreCard from "./FriendsTheatreCard";
import "../../css/book.slot.css";

export default function Book() {
  const navigate = useNavigate();
  const [adult, setAdult] = useState(4);
  const [adultPrice, setAdultPrice] = useState(200);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState("");
  const selectedTheatre = localStorage.getItem("selectedTheatre");
  const [msg, setMsg] = useState("");
  const [availableSlots, setAvailableSlots] = useState([]);
  const [lockedSlots, setLockedSlots] = useState([]);
  const [totalAdultPrice, setTotalAdultPrice] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const localhost = process.env.REACT_APP_LOCALHOST;
  const awsProdPublicDomain = process.env.REACT_APP_AWS_EC2_DOMAIN;

  const timeSlots = {
    "Couple Theatre": [
      "09:00 AM - 11:30 PM",
      "12:15 PM - 02:45 PM",
      "03:30 PM - 06:00 PM",
      "06:45 PM - 09:15 PM",
      "10:00 PM - 12:30 AM",
    ],
    "Friends Theatre": [
      "09:30 AM - 12:00 PM",
      "12:45 PM - 03:15 PM",
      "04:00 PM - 06:30 PM",
      "07:15 PM - 09:45 PM",
      "10:30 PM - 01:00 AM",
    ],
    "Family Theatre": [
      "10:00 AM - 01:00 PM",
      "02:00 PM - 05:00 PM",
      "06:00 PM - 09:00 PM",
      "10:00 PM - 01:00 AM",
    ],
  };

  useEffect(() => {
    setMsg("");
    handleBookingAvailability(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    const extraAdults = adult - 4;
    setTotalAdultPrice(extraAdults > 0 ? extraAdults * adultPrice : 0);
  }, [adult, adultPrice]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBookingAvailability = async (date) => {
    try {
      if (!date || !selectedTheatre) {
        alert("Please select date and theatre!");
        return;
      }
      // const response = await axios.post(`${localhost}/checkdatetimetheater`, {
      //   selectedDate: format(date, "dd/MM/yyyy"),
      //   selectedTheatre,
      //   times: timeSlots[selectedTheatre],
      // });
      const response = await axios.post(
        `${awsProdPublicDomain}/checkdatetimetheater`,
        {
          selectedDate: format(date, "dd/MM/yyyy"),
          selectedTheatre,
          times: timeSlots[selectedTheatre],
        }
      );

      const { data } = response.data;
      setAvailableSlots(data.availableSlots || []);
      setLockedSlots(data.lockedSlots || []);
      setMsg(data.message || "");
    } catch (error) {
      console.log("Err", error);
    }
  };

  const handleUserForm = async () => {
    try {
      // const response = await axios.post(`${localhost}/lockslot`, {
      //   selectedDate: format(selectedDate, "dd/MM/yyyy"),
      //   selectedTime,
      //   selectedTheatre,
      // });
      const response = await axios.post(`${awsProdPublicDomain}/lockslot`, {
        selectedDate: format(selectedDate, "dd/MM/yyyy"),
        selectedTime,
        selectedTheatre,
      });

      if (response.data.data === "Slot is locked successfully.") {
        localStorage.setItem(
          "selectedDate",
          format(selectedDate, "dd/MM/yyyy")
        );
        localStorage.setItem("selectedTime", selectedTime);
        localStorage.setItem("noofextraAdults", adult - 4);
        localStorage.setItem("totalAdultPrice", totalAdultPrice);
        navigate("/userform");
      } else {
        setMsg(response.data.data);
      }
    } catch (error) {
      console.log("Err", error);
    }
  };

  const handleAdult = (e) => {
    e.preventDefault();
    if (e.target.id === "addAdult") {
      if (
        (selectedTheatre === "Friends Theatre" && adult < 6) ||
        (selectedTheatre === "Family Theatre" && adult < 12)
      ) {
        setAdult(adult + 1);
      }
    }
    if (e.target.id === "removeAdult" && adult > 4) {
      setAdult(adult - 1);
    }
  };

  const handleSelectedDate = async (date) => {
    setSelectedDate(date);
    setMsg("");
    setAvailableSlots([]);
    setSelectedTime("");
    handleBookingAvailability(date);
  };

  const handleTimeSlotSelection = (time, isAvailable) => {
    if (isAvailable) {
      setSelectedTime(time);
      setMsg("Slot is free to book!");
    }
  };

  const renderTheatreCard = () => {
    switch (selectedTheatre) {
      case "Couple Theatre":
        return <CoupleTheatreCard />;
      case "Friends Theatre":
        return <FriendsTheatreCard />;
      case "Family Theatre":
        return <FamilyTheatreCard />;
      default:
        return null;
    }
  };

  const filterTimeSlots = (slots) => {
    const currentDate = new Date();
    const isToday =
      selectedDate.toLocaleDateString() === currentDate.toLocaleDateString();
    const oneHourLater = new Date(currentDate.getTime() + 60 * 60 * 1000);

    return slots.map((slot) => {
      const [startTime] = slot.split(" - ");
      const [startHour, startMinute] = startTime.match(/\d+/g);
      const startPeriod = startTime.split(" ")[1];
      const slotDate = new Date(selectedDate);
      slotDate.setHours(
        startPeriod === "PM" && startHour !== "12"
          ? +startHour + 12
          : +startHour
      );
      slotDate.setMinutes(+startMinute);

      const isPastTime = isToday && slotDate.getTime() <= currentDate.getTime();
      const isWithinOneHour =
        isToday && slotDate.getTime() <= oneHourLater.getTime();
      const isLocked = lockedSlots.includes(slot);

      return {
        slot,
        isAvailable: !isPastTime && !isWithinOneHour && !isLocked,
      };
    });
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <Container className="book-page mt-5">
      <Row>
        <Col lg={7}>{renderTheatreCard()}</Col>
        <Col lg={5}>
          <br />
          <h2 className="selected-room-title">
            Selected Theatre: {selectedTheatre}
          </h2>
          <div className="d-flex justify-content-start align-items-start flex-wrap">
            <div className="calendar-container mb-3">
              <Calendar
                minDate={new Date()}
                onChange={handleSelectedDate}
                value={selectedDate}
              />
            </div>
            <div className="selected-info">
              <p>
                <b>Selected Date: </b>
                <span>{format(selectedDate, "dd/MM/yyyy")}</span>
              </p>
              <p>
                <b>Selected Time: </b>
                <span>{selectedTime}</span>
              </p>
              <p>
                <b>Available Slots: </b>
              </p>
            </div>
            <div className="time-slot-buttons">
              {filterTimeSlots(timeSlots[selectedTheatre]).map(
                ({ slot, isAvailable }) => (
                  <Button
                    key={slot}
                    onClick={() => handleTimeSlotSelection(slot, isAvailable)}
                    className={`btn-time-slot ${
                      selectedTime === slot ? "selected" : ""
                    }`}
                    style={{
                      backgroundColor: isAvailable ? "#007bff" : "#c4c4c4",
                      cursor: isAvailable ? "pointer" : "not-allowed",
                      pointerEvents: isAvailable ? "auto" : "none",
                    }}
                  >
                    {slot}
                  </Button>
                )
              )}
            </div>
          </div>
          {msg && (
            <div
              className={`msg-box ${
                msg.includes("free") ? "free" : "not-free"
              }`}
            >
              {msg}
            </div>
          )}
          {msg === "Slot is free to book!" && selectedTime && (
            <>
              
               {selectedTheatre !== "Couple Theatre" && (
                <Button className="next-button" onClick={toggleModal}>
                  Add Adult
                </Button>
              )}
              <Button className="next-button" onClick={handleUserForm}>
                Next
              </Button>
             
            </>
          )}
        </Col>
      </Row>
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Extra Adult</h3>
            <button
              id="removeAdult"
              disabled={adult <= 4}
              onClick={handleAdult}
              className="btn"
            >
              -
            </button>
            <span className="adult-value">{adult}</span>{" "}
            <button
              id="addAdult"
              disabled={
                (selectedTheatre === "Friends Theatre" && adult >= 6) ||
                (selectedTheatre === "Family Theatre" && adult >= 12)
              }
              onClick={handleAdult}
              className="btn"
            >
              +
            </button>
            <p className="label">
              Adult Price (₹200 per head) i.e. {adult - 4} X {adultPrice} = ₹
              {totalAdultPrice}
            </p>
            <button className="btn close-btn" onClick={toggleModal}>
              Add
            </button>
            <button className="btn close-btn" onClick={toggleModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </Container>
  );
}
