import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { carouselData } from "../data/Data";
import { useNavigate } from "react-router-dom";

export default function Carousel() {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0); // State to track the current slide index

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const navigate = useNavigate();

  const bounceStyle = {
    animationName: "bounce",
    animationDuration: "2s",
    animationTimingFunction: "ease",
    animationIterationCount: "infinite",
    marginTop: "40px",
  };

  const waveStyle = {
    display: "inline-block",
    animationName: "wave",
    animationDuration: "2s",
    animationTimingFunction: "ease-in-out",
    animationIterationCount: "infinite",
    transformOrigin: "70% 70%",
    fontFamily: "Jokerman",
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex); // Update current slide index on slide change
    },
  };

  useEffect(() => {
    const styleElement = document.createElement("style");
    styleElement.type = "text/css";

    const keyframes = `
      @keyframes wave {
        0% { transform: rotate(0deg); }
        10% { transform: rotate(14deg); }
        20% { transform: rotate(-8deg); }
        30% { transform: rotate(14deg); }
        40% { transform: rotate(-4deg); }
        50% { transform: rotate(10deg); }
        60% { transform: rotate(0deg); }
        100% { transform: rotate(0deg); }
      }
    `;

    const responsiveStyles = `
      @media (max-width: 375px) {
        .carousel-caption {
          padding-left: 10px;
          padding-right: 10px;
        }

        .carousel-item img {
          width: 100%;
          max-height: 100vh;
        }

        .carousel-caption h3, .carousel-caption h6, .carousel-caption h1 {
          font-size: 1.5rem;
        }

        .carousel-caption button {
          width: 150px;
          font-size: 0.875rem;
        }

        .carousel-control-prev-icon,
        .carousel-control-next-icon {
          width: 20px;
          height: 20px;
        }

        .container-fluid {
          padding-left: 0 !important;
          padding-right: 0 !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }

      @media (max-width: 375px) {
        .carousel-caption {
          padding-left: 5px;
          padding-right: 5px;
        }

        .carousel-item img {
          width: 100%;
          max-height: 100vh;
        }

        .carousel-caption h3, .carousel-caption h6, .carousel-caption h1 {
          font-size: 1.3rem;
        }

        .carousel-caption button {
          width: 140px;
          font-size: 0.8rem;
        }

        .carousel-control-prev-icon,
        .carousel-control-next-icon {
          width: 18px;
          height: 18px;
        }

        .container-fluid {
          padding-left: 0 !important;
          padding-right: 0 !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }
    `;

    styleElement.appendChild(
      document.createTextNode(keyframes + responsiveStyles)
    );
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  const handleNavigation = () => {
    window.scrollTo(0, 0);
    navigate("/selecttheatre");
  };

  return (
    <>
      <div className="container-fluid p-0 mb-5">
        <div
          id="header-carousel"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <Slider ref={sliderRef} {...settings}>
              {carouselData.map((val, index) => (
                <div className="carousel-item" key={index}>
                  <img
                    className="w-100"
                    style={{
                      maxHeight: "100vh",
                    }}
                    src={val.img}
                    alt="Image"
                  />
                  <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                    {/* <h1
                      className="text-uppercase mb-3"
                      style={{
                        ...bounceStyle,
                        color: "transparent",
                        backgroundImage:
                          "linear-gradient(to right, #FF9933, #FFFFFF, #138808)",
                        WebkitBackgroundClip: "text",
                        backgroundClip: "text",
                      }}
                    >
                      Celebrate Freedom!
                    </h1>
                    <h2>
                      <p className="text-white">
                        <small>
                          Use Coupon <strong>FREEDOM500</strong>
                        </small>
                      </p>
                    </h2> */}

                    <div className="p-3" style={{ maxWidth: "700px" }}>
                      <h6 className="section-title text-white text-uppercase mb-3 animated slideInDown">
                        {val.subtitle}
                      </h6>
                      <h1 className="display-3 text-white mb-4 animated slideInDown">
                        Book <span style={waveStyle}>MiniFlicks</span> for an
                        unforgettable experience!
                      </h1>
                      <h5
                        className="text-primary mb-3 animate__animated animate__infinite"
                        style={bounceStyle}
                      >
                        <span className="text-sm">{val.body}</span>
                      </h5>
                      <button
                        className="btn btn-primary ml-10"
                        style={{
                          maxWidth: "200px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "20px auto",
                          animationName: "pulse",
                          animationDuration: "1s",
                          animationIterationCount: "infinite",
                          animationTimingFunction: "ease-in-out",
                        }}
                        onClick={handleNavigation}
                      >
                        Book Theatre!
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            onClick={previous}
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            onClick={next}
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </>
  );
}
