import React from "react";
import { socialIcons } from "../data/Data";
import { Link } from "react-router-dom";

export default function SocialIcons() {
  const handleWhatsapp = () => {
    const phoneNumber = "+919019162002";
    const message = "Hello, I would like to chat with you!";
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="col-lg-3 px-5">
        <div className="d-inline-flex align-items-center py-2">
          {socialIcons.map((val, index) => (
            <div key={index}>
              {val.isExternal ? (
                <a
                  className="me-3"
                  href={val.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {val.icon}
                </a>
              ) : (
                <Link className="me-3" to={val.url}>
                  {val.icon}
                </Link>
              )}
            </div>
          ))}
          <a
            href="#"
            className="me-3 whatsapp-icon"
            onClick={handleWhatsapp}
            style={{ position: "relative" }}
          >
            <i className="fab fa-whatsapp"></i>
            <span
              style={{
                visibility: "hidden",
                width: "120px",
                backgroundColor: "black",
                color: "#fff",
                textAlign: "center",
                borderRadius: "6px",
                padding: "5px 0",
                position: "absolute",
                zIndex: 1,
                bottom: "125%",
                left: "50%",
                marginLeft: "-60px",
                opacity: 0,
                transition: "opacity 0.3s"
              }}
              className="tooltip-text"
            >
              Click to chat!
            </span>
          </a>
        </div>
      </div>
    </>
  );
}
