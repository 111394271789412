import React, { useEffect, useState } from "react";
import { BsFillGrid3X3GapFill, BsPeopleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../../../css/admin.home.css";

const AdminHome = () => {
  const [totalOrderNumber, setTotalOrderNumber] = useState(0);
  const [adminTotalOrderNumber, setAdminTotalOrderNumber] = useState(0);
  const navigate = useNavigate();
  const awsProdPublicIP = process.env.REACT_APP_AWS_EC2_PUBLIC_IP;
  const awsProdPublicDomain = process.env.REACT_APP_AWS_EC2_DOMAIN;
  const localhost = process.env.REACT_APP_LOCALHOST;
  const accessToken = localStorage.getItem("admin-accessToken")
  const handleLogin  = () => {
    navigate("/admin")
  }

  useEffect(() => {
    // <---Prod --->
    axios
      .get(`${awsProdPublicDomain}/admin/customers`)
      .then((data) => {
        setTotalOrderNumber(data.data.message.length);
      })
      .catch((err) => console.log(err));

    // <--- localhost --->
    // axios
    //   .get(`${localhost}/admin/customers`)
    //   .then((data) => {
    //     setTotalOrderNumber(data.data.message.length);
    //   })
    //   .catch((err) => console.log(err));

    // <---Prod --->
    axios
      .get(`${awsProdPublicDomain}/admin/slotfrom-admin`)
      .then((data) => {
        setAdminTotalOrderNumber(data.data.message.length);
      })
      .catch((err) => console.log(err));

    // < --- localhost ---->
    // axios
    //   .get(`${localhost}/admin/slotfrom-admin`)
    //   .then((data) => {
    //     setAdminTotalOrderNumber(data.data.message.length);
    //   })
    //   .catch((err) => console.log(err));
  }, []);

  const handleAdminCustomers = () => {
    navigate("/admin/customers-details");
  };

  const handleOrders = () => {
    navigate("/admin/customer-booked-slots");
  };
  const handleOrderFromAdmin = () => {
    navigate("/admin/admin-booked-slots");
  };

  const handleBookSlot = () => {
    navigate("/admin/book-slot");
  };

  const handlePackageUpdateChanges = () => {
    navigate("/admin/update-details");
  };
  const hadnelAddCouponCode = () => {
    navigate("/admin/add-coupon");
  };

  const handleAddImage = () => {
    navigate("/admin/add-photos");
  };
  return (
    accessToken ? (
      <main className="admin-home main-container">
      <div className="main-title d-flex justify-content-between align-items-center">
        <h3 style={{ color: "wheat" }}>DASHBOARD</h3>
      </div>
      <div className="main-cards">
        <div
          className="card"
          onClick={handleOrderFromAdmin}
          style={{ cursor: "pointer" }}
        >
          <div className="card-inner">
            <h3>Orders Booked (By Admin)</h3>
            <BsFillGrid3X3GapFill className="card_icon" />
          </div>
          <h1>{adminTotalOrderNumber}</h1>
        </div>
        <div
          className="card"
          onClick={handleOrders}
          style={{ cursor: "pointer" }}
        >
          <div className="card-inner">
            <h3>Orders (By Customers)</h3>
            <BsFillGrid3X3GapFill className="card_icon" />
          </div>
          <h1>{totalOrderNumber}</h1>
        </div>
        <div
          className="card"
          onClick={handleAdminCustomers}
          style={{ cursor: "pointer" }}
        >
          <div className="card-inner">
            <h3>
              Customer <br />
              Details
            </h3>
            <BsPeopleFill className="card_icon" />
          </div>
        </div>
        <div
          className="card"
          onClick={handleBookSlot}
          style={{ cursor: "pointer" }}
        >
          <div className="card-inner">
            <h3>Book Slot</h3>
          </div>
        </div>
        <div
          className="card"
          onClick={handlePackageUpdateChanges}
          style={{ cursor: "pointer" }}
        >
          <div className="card-inner">
            <h3>Update Package Details</h3>
            <BsFillGrid3X3GapFill className="card_icon" />
          </div>
        </div>
        <div
          className="card"
          onClick={handleAddImage}
          style={{ cursor: "pointer" }}
        >
          <div className="card-inner">
            <h3>Add Image </h3>
            <BsFillGrid3X3GapFill className="card_icon" />
          </div>
        </div>
        <div
          className="card"
          onClick={hadnelAddCouponCode}
          style={{ cursor: "pointer" }}
        >
          <div className="card-inner">
            <h3>Add Coupon Code</h3>
            <BsFillGrid3X3GapFill className="card_icon" />
          </div>
        </div>
      </div>
    </main>
    )
    : (
      <>
      `No Access Token Found `
      <button>Login</button>
      </>
    )
  );
};

export default AdminHome;
