import React from "react";
import CommonHeading from "../common/CommonHeading";
import "../../css/service.css";
import familyphoto from "../../cover/familyphoto.jpeg";
import friendsphoto from "../../cover/friendsphoto.jpeg";
import Carousel from 'react-bootstrap/Carousel';

const imageStyle = {
  transition: "transform 0.3s ease-in-out",
  borderRadius: "15px",
  height: "300px", // Set a fixed height for the images
  objectFit: "cover", // Ensure images cover the entire height
};

export default function Services() {
  return (
    <>
      <div className="services-container-xxl py-5">
        <div className="unique-services-container">
          <div className="unique-services-container">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
              <CommonHeading heading="Why" title="Choose Us?" />
            </div>
            <div className="row g-4">
              <div className="col-12 unique-services-card-container">
                <div className="col-12 unique-services-card-container">
                  <div className="unique-services-card splash-effect">
                    <div className="content-section">
                      <h3 className="unique-services-card-heading">Eat, Enjoy, Work or Watch a Movie</h3>
                      <span className="unique-services-card-span fade-in-up delay-1">Private Screenings</span>
                      <p className="unique-services-card-p fade-in-up delay-2">
                        MiniFlicks provides a distinctive and exclusive experience
                        with private screenings, ideal for birthdays, date nights,
                        or family movie nights.
                      </p>
                      <span className="unique-services-card-span fade-in-up delay-3">Business Events</span>
                      <p className="unique-services-card-p fade-in-up delay-4">
                        Make your message larger than life on the BIG Screen!
                        Ideal for team building, training, and presentations.
                      </p>
                      <span className="unique-services-card-span fade-in-up delay-5">Birthday Packages</span>
                      <p className="unique-services-card-p fade-in-up delay-6">
                        A birthday fit for a movie star with their favorite movie
                        of choice on the big screen! Packages are available for
                        Kid’s Combos, popcorn, and drinks.
                      </p>
                    </div>
                    <div className="carousel-section">
                      <Carousel>
                        <Carousel.Item key="1">
                          <img
                            className="d-block w-100"
                            src="/assets/img/WhatsApp Image 2024-01-01 at 1.49.52 PM.jpeg"
                            alt="First slide"
                            style={imageStyle}
                          />
                        </Carousel.Item>
                        <Carousel.Item key="2">
                          <img
                            className="d-block w-100"
                            src="/assets/img/WhatsApp Image 2024-01-01 at 1.49.45 PM.jpeg"
                            alt="Second slide"
                            style={imageStyle}
                          />
                        </Carousel.Item>
                        <Carousel.Item key="3">
                          <img
                            className="d-block w-100"
                            src={friendsphoto}
                            alt="Third slide"
                            style={imageStyle}
                          />
                        </Carousel.Item>
                        <Carousel.Item key="4">
                          <img
                            className="d-block w-100"
                            src={familyphoto}
                            alt="Fourth slide"
                            style={imageStyle}
                          />
                        </Carousel.Item>
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
