import React, { useEffect, useState } from "react";
import "../../../css/admin.changepackage.details.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const AdminChangePackageDetails = () => {
  const [selectedTheater, setSelectedTheater] = useState("None");
  const [selectedPackageType, setSelectedPackageType] = useState("None");
  const [selectedCombinedData, setSelectedCombinedData] = useState("");
  const [updatedValue, setUpdatedValue] = useState("");
  const [updateMessage, setUpdateMessage] = useState("");
  const awsProdPublicIP = process.env.REACT_APP_AWS_EC2_PUBLIC_IP;
  const awsProdPublicDomain = process.env.REACT_APP_AWS_EC2_DOMAIN;
  const localhost = process.env.REACT_APP_LOCALHOST;
  const accessToken = localStorage.getItem("admin-accessToken")
  const navigate = useNavigate()
  const handleLogin  = () => {
    navigate("/admin")
  }
  const handleUpdatePrice = async () => {
    // <---- Prod ---->
    await axios
      .patch(`${awsProdPublicDomain}/admin/packagedetails-update`, {
        selectedTheater,
        selectedPackageType,
        updatedValue,
      })
    .then((data) => {
      console.log(data.data.message);
      setUpdateMessage(data.data.message);
      setSelectedTheater("None");
      setSelectedPackageType("None");
      setUpdatedValue("");
      setSelectedCombinedData("");
    })
    .catch((err) => {
      console.log(err);
      setUpdateMessage("Error updating price.");
    });



      // <---- localhost ---->
      // await  axios
      //     .patch(`${localhost}/admin/packagedetails-update`, {
      //       selectedTheater,
      //       selectedPackageType,
      //       updatedValue,
      //     })
      // .then((data) => {
      //   console.log(data.data.message);
      //   setUpdateMessage(data.data.message);
      //   setSelectedTheater("None");
      //   setSelectedPackageType("None");
      //   setUpdatedValue("");
      //   setSelectedCombinedData("");
      // })
      // .catch((err) => {
      //   console.log(err);
      //   setUpdateMessage("Error updating price.");
      // });
  };

  useEffect(() => {
    if (selectedTheater !== "None" && selectedPackageType !== "None") {
      axios
        .post(`${awsProdPublicDomain}/admin/packagedetails-get`, {
          selectedTheater,
          selectedPackageType,
        })
        .then((data) => {
          setSelectedCombinedData(data.data.message);
        })
        .catch((err) => {
          console.log("Error:", err);
          setSelectedCombinedData("");
        });
      
      // axios
      //   .post(`${localhost}/admin/packagedetails-get`, {
      //     selectedTheater,
      //     selectedPackageType,
      //   })
      //   .then((data) => {
      //     setSelectedCombinedData(data.data.message);
      //   })
      //   .catch((err) => {
      //     console.log("Error:", err);
      //     setSelectedCombinedData("");
      //   });
    }
  }, [selectedTheater, selectedPackageType]);

  return (
    accessToken ? (
      <>
      <div className="admin-page-background">
      <div className="unique-change-package-form">
        <div className="form-group">
          <label className="unique-label" htmlFor="selectedTheater">
            Select Theater
          </label>
          <select
            id="selectedTheater"
            className="form-control custom-select-style"
            value={selectedTheater}
            onChange={(e) => setSelectedTheater(e.target.value)}
            required
          >
            <option value="None">None</option>
            <option value="Couple Theatre">Couple Theatre</option>
            <option value="Friends Theatre">Friends Theatre</option>
            <option value="Family Theatre">Family Theatre</option>
          </select>
        </div>

        <div className="form-group">
          <label className="unique-label" htmlFor="selectedPackageType">
            Select Package Type
          </label>
          <select
            id="selectedPackageType"
            className="form-control custom-select-style"
            value={selectedPackageType}
            onChange={(e) => setSelectedPackageType(e.target.value)}
            required
          >
            <option value="None">None</option>
            <option value="BASIC">BASIC</option>
            <option value="STANDARD">STANDARD</option>
            <option value="PREMIUM">PREMIUM</option>
            <option value="ULTRA_PREMIUM">ULTRA PREMIUM</option>
          </select>
        </div>

        <div className="unique-Theatre form-group">
          <label className="unique-label" htmlFor="priceField">
            Current Price
          </label>
          <input
            id="priceField"
            className="unique-input"
            type="text"
            value={selectedCombinedData}
            readOnly
            disabled
          />
        </div>

        <div className="unique-Theatre form-group">
          <label className="unique-label" htmlFor="updateField">
            Enter Updated Value
          </label>
          <input
            id="updateField"
            className="unique-input"
            type="text"
            value={updatedValue}
            onChange={(e) => setUpdatedValue(e.target.value)}
          />
        </div>

        <button className="unique-submit-button" onClick={handleUpdatePrice}>
          Update the Price
        </button>

        {updateMessage && <p className="update-message">{updateMessage}</p>}
      </div>
    </div>
      </>
    )
    : (
      <>
      `No Access Token Found `
      <button onClick={handleLogin}>Login</button>
      </>
    )
  );
};

export default AdminChangePackageDetails;
