import React, { useState, useEffect } from "react";
import "../css/decoration.details.css";
import { useNavigate } from "react-router-dom";

import birthdayIcon from "../components/data/birthday.png";
import momtobe from "../components/data/momtobe.jpg";
import anniversaryIcon from "../components/data/ann.png";
import romanticDateIcon from "../components/data/date.png";
import marriageProposalIcon from "../components/data/prop.png";
import brideToBeIcon from "../components/data/bride.png";
import groomToBeIcon from "../components/data/groom.png";
import farewellIcon from "../components/data/fare.png";
import congratulationsIcon from "../components/data/congrats.png";
import othersIcon from "../components/data/others.png";

const DecorationDetails = () => {
  const [selectedDecoration, setSelectedDecoration] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [secondInputValue, setSecondInputValue] = useState("");
  const [showSecondInput, setShowSecondInput] = useState(false);
  const navigate = useNavigate();
  const selectedTheatreType = localStorage.getItem("selectedTheatreType");

  const details = [
    { icon: birthdayIcon, label: "Birthday" },
    { icon: anniversaryIcon, label: "Anniversary" },
    { icon: romanticDateIcon, label: "Romantic Date" },
    { icon: marriageProposalIcon, label: "Marriage Proposal" },
    { icon: brideToBeIcon, label: "Bride to be" },
    { icon: groomToBeIcon, label: "Groom to be" },
    { icon: farewellIcon, label: "Farewell" },
    { icon: congratulationsIcon, label: "Congratulations" },
    { icon: momtobe, label: "Mom to be" },
    { icon: othersIcon, label: "Others" },
  ];

  const handlePrev = () => {
    navigate("/packageselection");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getPlaceholders = (label) => {
    localStorage.setItem("selectedDecorationType", label);
    switch (label) {
      case "Birthday":
        return ["Nick name of birthday person"];
      case "Bride to be":
        return ["Name of the bride"];
      case "Farewell":
        return ["Occasion Person Name"];
      case "Congratulations":
        return ["Occasion Person Name"];
      case "Mom to be":
        return ["Occasion Person Name"];
      case "Groom to be":
        return ["Name of the groom"];
      case "Anniversary":
        return ["🤵‍♂ Partner Name", "👩‍💼 Partner Name"];
      case "Romantic Date":
        return ["🤵‍♂ Partner Name", "👩‍💼 Partner Name"];
      case "Marriage Proposal":
        return ["Occasion Person Name"];
      case "Others":
        return ["Enter Occasion Name", "Enter Other Details"];
      default:
        return ["Enter details"];
    }
  };

  const handleItemClick = (label) => {
    setSelectedDecoration(label);
    setInputValue("");
    setSecondInputValue("");
    setShowSecondInput(
      label === "Anniversary" || label === "Romantic Date" || label === "Others"
    );
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSecondInputChange = (e) => {
    setSecondInputValue(e.target.value);
  };

  const handleDecorationDetails = () => {
    let details = inputValue;
    if (showSecondInput) {
      details += `, ${secondInputValue}`;
    }
    localStorage.setItem("selectedDecorationDetails", details);
    selectedTheatreType === "BASIC"
      ? navigate("/viewsummary")
      : navigate("/cakedetails");
  };

  const isFormValid = () => {
    return inputValue && (!showSecondInput || secondInputValue);
  };

  return (
    <div className="white-container">
      <div className="decoration-parent-container">
        <h2 className="mb-4 text-dark">Occation Details</h2>
        <div className="details-list">
          {details.map((detail, index) => (
            <div
              key={index}
              className={`detail-item ${
                selectedDecoration === detail.label ? "selected" : ""
              }`}
              onClick={() => handleItemClick(detail.label)}
            >
              <img src={detail.icon} alt={detail.label} className="icon" />
              <span className="label">{detail.label}</span>
            </div>
          ))}
        </div>
        {selectedDecoration && (
          <div className="input-fields">
            <div className="input-box">
              <input
                type="text"
                placeholder={getPlaceholders(selectedDecoration)[0]}
                value={inputValue}
                onChange={handleChange}
                required
              />
              <br />
              {showSecondInput && (
                <div className="input-box">
                  <input
                    type="text"
                    placeholder={getPlaceholders(selectedDecoration)[1]}
                    value={secondInputValue}
                    onChange={handleSecondInputChange}
                    required
                  />
                </div>
              )}
            </div>
            <div className="button-container mt-3">
              <button
                id="prevButton"
                onClick={handlePrev}
                className="btn btn-secondary"
              >
                &lt; Back
              </button>{" "}
              <button
                id="nextButton"
                onClick={handleDecorationDetails}
                className="btn btn-primary"
                disabled={!isFormValid()}
              >
                Next Step
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DecorationDetails;
