import React, { useEffect } from "react";
import CommonHeading from "../common/CommonHeading";
import { TheaterItems } from "../data/Data";
import { useNavigate } from "react-router-dom";
export default function Theatre() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const handleNavigation = () => {
    window.scrollTo(0, 0);
    navigate("/selecttheatre");
  };
  return (
    <>
      <div className="container-xxl py-5">
        <div className="container">
          <CommonHeading
            heading="Our Theatre"
            title="  Theatres"
            subtitle="Explore Our"
          />
          <div className="row g-4">
            {TheaterItems.map((item, key) => (
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="Theatre-item shadow rounded overflow-hidden">
                  <div className="position-relative">
                    <img className="img-fluid" src={item.img} alt="img" />
                  </div>
                  <div className="p-4 mt-2">
                    <div className="d-flex justify-content-between mb-3">
                      <h5 className="mb-0">{item.name}</h5>
                      <div className="ps-2">{item.star}</div>
                    </div>
                    <p className="text-body mb-3">{item.description}</p>
                    <div className="d-flex justify-content-between"></div>
                  </div>
                </div>
              </div>
            ))}
            <button
              class="btn btn-primary ml-10"
              style={{
               maxWidth: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px auto',
    animationName: 'pulse',
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out'
              }}
              onClick={handleNavigation}
            >
              Book Theatre!
            </button>
          </div>
        </div>
        {/* <a
                className="btn btn-sm btn-primary rounded py-2 px-4"
                href=""
                >
                {item.yellowbtn}
                </a> */}
        {/* <a className="btn btn-sm btn-dark rounded py-2 px-4" href="">
                {item.darkbtn}
                </a> */}
      </div>
    </>
  );
}
