import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "../css/theatre.selector.css";
import couplecover from "../cover/couplecover.jpeg";
import friendscover from "../cover/friendscover.jpeg";
import familycover from "../cover/familycover.jpeg";
const TheatreSelector = () => {
  const [selectedTheatre, setSelectedTheatre] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const handleBookingDetails = () => {
    navigate("/book");
  };

  const handleTheatreSelection = (Theatre) => {
    setSelectedTheatre(Theatre);
    localStorage.setItem("selectedTheatre", Theatre);
  };

  return (
    <div className="theatre-selection-container container mt-5">
      <h3>Please Select Theatre</h3>
      <div className="row">
        <div
          className="col-lg-4 col-md-6 mb-3"
          onClick={() => handleTheatreSelection("Couple Theatre")}
        >
          <div
            className={`theatre-card ${
              selectedTheatre === "Couple Theatre" ? "selected" : ""
            }`}
            onClick={handleBookingDetails}
            style={{ cursor: "pointer" }}
          >
            <img
              src={couplecover}
              className="card-img-top"
              alt="Couple Cover Image"
            />
            <div className="card-body">
              <h4 className="card-title">Couple Theatre</h4>
              <p className="card-text">
                {"  "}
                Decorated for couples, including taxes
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <i class="fa fa-users" aria-hidden="true"></i>
                <p className="card-text"> 2 people</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 mb-3"
          onClick={() => handleTheatreSelection("Friends Theatre")}
        >
          <div
            className={`theatre-card ${
              selectedTheatre === "Friends Theatre" ? "selected" : ""
            }`}
            onClick={handleBookingDetails}
            style={{ cursor: "pointer" }}
          >
            <img
              src={friendscover}
              className="card-img-top"
              alt="Friends Cover Image"
            />
            <div className="card-body">
              <h4 className="card-title">Friends Theatre</h4>
              <p className="card-text">
                {"  "}
                Decorated for 4 or less people including taxes (₹ 200
                extra per Person)
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <i class="fa fa-users" aria-hidden="true"></i>

                <p className="card-text"> 4 - 6 People</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 mb-3"
          onClick={() => handleTheatreSelection("Family Theatre")}
        >
          <div
            className={`theatre-card ${
              selectedTheatre === "Family Theatre" ? "selected" : ""
            }`}
            onClick={handleBookingDetails}
            style={{ cursor: "pointer" }}
          >
            <img
              src={familycover}
              className="card-img-top"
              alt="Family Cover Image"
            />
            <div className="card-body">
              <h4 className="card-title">Family Theatre</h4>
              <p className="card-text">
                {"  "}
                <p className="card-text">
                  Decorated for 4 or less people including taxes (₹ 200
                  extra per Person)
                </p>
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <i class="fa fa-users" aria-hidden="true"></i>

                <p className="card-text">4 - 12 People</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TheatreSelector;
