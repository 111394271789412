import React, { useState, useEffect } from "react";
import LazyLoad from "react-lazyload";
import RedVelvetRound from "../cakes/RedVelvetRound.jpg";
import BlackForst from "../cakes/Black Forest  Egg Cakes.jpg";
import RedVelvetHeart from "../cakes/Red Velvet Heart Cake Egg Cake Heart.jpg";
import DeathChocolate from "../cakes/Death By Chocolate  Eggless Cake.jpg";
import FruitCake from "../cakes/Fresh Fruit  Egg Cakes.jpg";
import BelgiumChocolate from "../cakes/Belgium Chocolate  Egg Cakes.jpg";
import Strawberry from "../cakes/Strawberry Cake - Egg.jpeg";
import HoneyAlmond from "../cakes/Honey Almond Cake - Eggless.jpeg";
import pinatacake from "../cakes/Dark Chocolate Heart Pinata Cake.jpg";
import customizedCake from "../cover/customizedCake.webp";
import "../css/cake.selector.css";
import { useNavigate } from "react-router-dom";

const CakeDetails = () => {
  const [selectedCake, setSelectedCake] = useState("");
  const [nameonCake, setNameOnCake] = useState("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [optionalGifts, setOptionalGifts] = useState({
    rose: "NA",
    teddy: "NA",
    heartPillow: "NA",
    cat: "NA",
  });
  const navigate = useNavigate();

  const selectedTheatreType = localStorage.getItem("selectedTheatreType");

  useEffect(() => {
    if (!selectedCake) {
      setIsConfirmModalOpen(false);
    }
  }, [selectedCake]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cakeNames = [
    { name: "BlackForest", image: BlackForst },
    { name: "Red velvet Round", image: RedVelvetRound },
    { name: "Red Velvet Heart", image: RedVelvetHeart },
    {
      name: "Pineapple",
      image: "https://www.cakesworld.in/images/web/pineapple_22102.jpg",
    },
    {
      name: "Mango Delight",
      image:
        "https://theambrosia.com/wp-content/uploads/2021/11/Mango-Delight.jpg",
    },
    {
      name: "Blueberry",
      image:
        "https://assets.flowersnfruits.com/uploads/product-pics/1687854541_13202.png",
    },
    { name: "Death by chocolate", image: DeathChocolate },
    {
      name: "Irish coffee",
      image: "https://cakefarm.in/wp-content/uploads/2020/06/Irish-Coffee.jpg",
    },
    { name: "Belgian chocolate", image: BelgiumChocolate },
    { name: "Strawberry", image: Strawberry },
    { name: "Honey Almond", image: HoneyAlmond },
    {
      name: "ButterScotch",
      image:
        "https://cdn.igp.com/f_auto,q_auto,t_pnopt19prodlp/products/p-special-butterscotch-cake-half-kg--109218-m.jpg",
    },
    {
      name: "WhiteForest ",
      image:
        "https://shreemsweetsandbakery.com/wp-content/uploads/2021/02/WHITE-FOREST-600x600.jpg",
    },
    {
      name: "Chocolate",
      image:
        "https://www.thebakerchick.com/wp-content/uploads/2013/06/choccake2-scaled.jpg",
    },
  ];

  const specialCakeNames = [
    {
      name: "Rasmalai",
      price: 199,
      image:
        "https://www.expressluv.com/cdn/shop/files/butterscotch-cake-with-rasmalai-2kg_1_856x.png?v=1712002650",
    },
    { name: "Fresh Fruit", price: 199, image: FruitCake },
    {
      name: "Chocolate Truffle",
      price: 199,
      image:
        "https://cakeconnection.in/wp-content/uploads/2021/07/CLASSIC-CHOCOLATE-CAKE.jpg",
    },
    {
      name: "Chocolate Almond",
      price: 199,
      image:
        "https://cdn.igp.com/f_auto,q_auto,t_prodl/products/p-chocolate-almond-cake-half-kg--67974-m.jpg",
    },
    { name: "Chocolate Pinata", price: 249, image: pinatacake },
    {
      name: "Customized Cake",
      price: "",
      image: customizedCake,
    },
  ];

  const handleCakeChange = (cake) => {
    setSelectedCake(cake);
    const normalCakeSelected = cakeNames.some(
      (CakeObj) => CakeObj.name === cake
    );
    for (
      let i = 0;
      normalCakeSelected ? i < cakeNames.length : i < specialCakeNames.length;
      i++
    ) {
      if (
        normalCakeSelected
          ? cakeNames[i].name === cake
          : specialCakeNames[i].name === cake
      ) {
        localStorage.setItem(
          "selectedCake",
          normalCakeSelected ? cakeNames[i].name : specialCakeNames[i].name
        );
        localStorage.setItem(
          "selectedCakePrice",
          normalCakeSelected
            ? 0
            : specialCakeNames[i].name !== "Chocolate Pinata"
            ? 199
            : 249
        );
      }
    }
    setIsConfirmModalOpen(true);
  };

  return (
    <div className="cake-container">
      {selectedTheatreType !== "BASIC" && (
        <>
          <h4>Choose the flavour of your choice (Half kg)</h4>
          <div className="cake-options">
            {cakeNames.map((cake) => (
              <div
                key={cake.name}
                className={`cake-option ${
                  selectedCake === cake.name ? "selected" : ""
                }`}
                onClick={() => handleCakeChange(cake.name)}
              >
                <LazyLoad height={200} offset={100} once>
                  <img
                    src={cake.image}
                    alt={cake.name}
                    className="cake-image"
                  />
                </LazyLoad>
                <span>{cake.name}</span>
              </div>
            ))}
          </div>
        </>
      )}
      {selectedTheatreType !== "BASIC" && (
        <>
          <h4>Special Cakes</h4>
          <div className="cake-options">
            {specialCakeNames.map((cake) => (
              <div
                key={cake.name}
                className={`cake-option ${
                  selectedCake === cake.name ? "selected" : ""
                }`}
                onClick={() => handleCakeChange(cake.name)}
              >
                <LazyLoad height={200} offset={100} once>
                  <img
                    src={cake.image}
                    alt={cake.name}
                    className="cake-image"
                  />
                </LazyLoad>
                <span>{cake.name}</span>
                <span>
                  {cake.name === "Customized Cake"
                    ? cake.price
                    : `+₹${cake.price}`}
                </span>
              </div>
            ))}
          </div>
        </>
      )}
      {selectedTheatreType !== "BASIC" && selectedCake && (
        <div className="input-group-single-line">
          <label>
            <span style={{ color: "red" }}>*</span> Name on Cake:
          </label>
          <input
            type="text"
            value={nameonCake}
            onChange={(e) => setNameOnCake(e.target.value)}
            className="input-field"
          />
        </div>
      )}
      {nameonCake && (
        <b>
          <p>Entered Name for Cake: {nameonCake}</p>
        </b>
      )}
      {selectedTheatreType !== "BASIC" ? (
        <button
          id="proceedPay"
          className="btn btn-primary"
          onClick={() => {
            if (selectedCake && nameonCake) {
              localStorage.setItem("nameonCake", nameonCake);
              localStorage.setItem(
                "optionalGifts",
                JSON.stringify(optionalGifts)
              );
              navigate("/viewsummary");
            }
          }}
          disabled={!(selectedCake && nameonCake)}
        >
          View Summary
        </button>
      ) : (
        <>
          <button
            id="proceedPay"
            className="btn btn-primary"
            onClick={() => {
              localStorage.setItem(
                "optionalGifts",
                JSON.stringify(optionalGifts)
              );
              navigate("/viewsummary");
            }}
          >
            View Summary
          </button>
        </>
      )}
    </div>
  );
};

export default CakeDetails;
