import React, { useEffect } from "react";
import "../css/faq.css";

const Faq = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="faq-container">
      <h2 className="faq-heading">FAQ</h2>
      {faqData.map((item, index) => (
        <div className="faq-item" key={index}>
          <h3>{item.question}</h3>
          <p>{item.answer}</p>
        </div>
      ))}
    </div>
  );
};

const faqData = [
  {
    question: "Q. Illegal/Prohibited contents are not allowed.",
    answer: "A. Customers must not stream any content which is illegal/prohibited as per Indian laws. We will not be liable for any consequences that arise out of violation of this condition, and we will not allow such customers to book with us again."
  },
  {
    question: "Q. Customers must not make any changes to the configuration of technical equipment.",
    answer: "A. Customers should not make adjustments or modifications to the setup of projector, screen, laptop and sound system. If any changes are required, we will do it from our end."
  },
  {
    question: "Q. Minors are not allowed to book the theater.",
    answer: "A. Individuals below 18 years of age cannot book the theater. Legal guardians can book the theater and bring their minor ward(s) along with them."
  },
  {
    question: "Q. Refund Policy",
    answer: "A. Full Refund of the Amount will be given if the booking is cancelled at least 72 hours before the booking time."
  },
  {
    question: "Q. In case of any technical interruption from our end, appropriate refund will be provided.",
    answer: "A. If there is any failure in working of the projector, laptop, internet access or sound system - we will reimburse the paid amount after deducting the rent for the period of time before interruption."
  },
  {
    question: "Q. Customers will be liable to pay in case of any damage to the theater caused by them.",
    answer: "A. If there is any damage to any of the technical or non-technical item in the theater including the walls, lights, seating, etc. the customers will have to pay for it."
  },
  {
    question: "Q. You are responsible for your belongings.",
    answer: "A. We will not be responsible in case of loss of personal belongings of any nature. Customers must take care of their belongings."
  },
  {
    question: "Q. The booking period includes set up and check out time.",
    answer: "A. Customers must vacate the theater along with all their belongings on or before the end time."
  },
  {
    question: "Q. Cleaning fee up to Rs 500 will be charged in cases where significant cleaning would be required after check out.",
    answer: "A. In cases where cleaners would be required to clean the garbage, leftovers and other wastes after the customers check out, the customers will have to pay appropriate cleaning fee."
  },
  {
    question: "Q. Restricted consumption",
    answer: "A. Customers are not allowed to smoke, consume alcoholic beverages or any illegal substance inside the theater. Appropriate action would be taken against such customers."
  },
];

export default Faq;
