import React from "react";
import "./css/style.css";
import "./css/bootstrap.min.css";
import "./css/animate.css";
import "./css/animate.min.css";
import "./App.css";
import { createBrowserRouter } from "react-router-dom";
import {
  Home,
  AboutUs,
  Contact,
  PageNotFound,
  Services,
  Team,
  Testimonial,
  UserLayout,
} from "./pages/index";
import PaymentSuccessPage from "./pages/PayementSuccess";
import AdminLogin from "./components/Admin/Login/Login.jsx";
import AdminDashboard from "./components/Admin/Dashboard/Dashboard.jsx";
import TheatreSelector from "./pages/TheatreSelector.js";
import Book from "./components/home/Book.js";
import PackageSelection from "./pages/PackageSelection.js";
import DecorationDetails from "./pages/Decoration.js";
import CakeDetails from "./pages/CakeDetails.js";
import TheaterBookingForm from "./pages/UserDetails.js";
import AdminCustomerDetails from "./components/Admin/CustomersDetails/CustomersDetails.jsx";
import AdminCustomerOrders from "./components/Admin/CustomerBookedSlots/CustomerBookedSlots.jsx";
import AdminBookSlot from "./components/Admin/BookingSlot/BookingSlot.jsx";
import AdminSideSlots from "./components/Admin/AdminBookedSlots/AdminBookedSlots.jsx";
import AdminChangePackageDetails from "./components/Admin/ChangePackageDetails/ChangePackageDetails.jsx";
import ImageGallery from "./pages/ImageGallery.js";
import TheatreGallery from "./pages/TheatreGallery.js";
import AdminAddCouponCode from "./components/Admin/CouponCode/CouponCode.jsx";
import AdminAddImgGallery from "./components/Admin/ImgGallery/ImgGallery.jsx";
import RefundPolicy from "./pages/RefundPolicy.jsx";
import TnC from "./pages/TnC.jsx";
import Faq from "./pages/Faq.jsx";
import PrivacyPolicy from "./components/home/PrivacyPolicy.js";
import PaymentFailure from "./components/payment/PaymentFailure.jsx";
import SummaryModal from "./pages/SummaryModel.js";
// import PrivacyPolicy from "./pages/PrivacyPolicy";
const Routes = createBrowserRouter([
  // user - routes
  {
    path: "/",
    element: <UserLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "book",
        element: <Book />,
      },
      {
        path: "userform",
        element: <TheaterBookingForm />,
      },
      {
        path: "packageselection",
        element: <PackageSelection />,
      },
      {
        path: "decoration",
        element: <DecorationDetails />,
      },
      {
        path: "cakedetails",
        element: <CakeDetails />,
      },
      {
        path: "team",
        element: <Team />,
      },
      {
        path: "testimonial",
        element: <Testimonial />,
      },
      {
        path: "about",
        element: <AboutUs />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "pp",
        element: <PrivacyPolicy />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
      {
        path: "services",
        element: <Services />,
      },
      {
        path: "paymentsuccess",
        element: <PaymentSuccessPage />,
      },
      {
        path: "paymentfail",
        element: <PaymentFailure />,
      },
      {
        path: "selecttheatre",
        element: <TheatreSelector />,
      },
      {
        path: "gallery",
        element: <ImageGallery />,
      },
      {
        path: "gallery/:folderName",
        element: <TheatreGallery />,
      },
      {
        path: "tnc",
        element: <TnC />,
      },
      {
        path: "refund-policy",
        element: <RefundPolicy />,
      },
      {
        path: "faq",
        element: <Faq />,
      },
      {
        path: "viewsummary",
        element: <SummaryModal />,
      },
      //  {
      //   path: "pp",
      //   element: <PrivacyPolicy />,
      // },
    ],
  },
  //admin-routes
  {
    path: "/admin",
    element: <AdminLogin />,
  },
  {
    path: "/admin/dashboard",
    element: <AdminDashboard />,
  },
  {
    path: "/admin/customers-details",
    element: <AdminCustomerDetails />,
  },
  {
    path: "/admin/book-slot",
    element: <AdminBookSlot />,
  },
  {
    path: "/admin/customer-booked-slots",
    element: <AdminCustomerOrders />,
  },
  {
    path: "/admin/admin-booked-slots",
    element: <AdminSideSlots />,
  },
  {
    path: "/admin/update-details",
    element: <AdminChangePackageDetails />,
  },
  {
    path: "/admin/add-coupon",
    element: <AdminAddCouponCode />,
  },
  {
    path: "/admin/add-photos",
    element: <AdminAddImgGallery />,
  },
]);

export default Routes;
