import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../css/admin.add.photo.css";
import axios from "axios";

const AdminAddImgGallery = () => {
  const [selectedTheater, setSelectedTheater] = useState("None");
  const [images, setImages] = useState([]);
  const [file, setFile] = useState(null);
  const [enlargedImage, setEnlargedImage] = useState(null);
  const awsProdPublicDomain = process.env.REACT_APP_AWS_EC2_DOMAIN;
  const localhost = process.env.REACT_APP_LOCALHOST;
  const accessToken = localStorage.getItem("admin-accessToken");
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const navigate = useNavigate()
  const handleLogin  = () => {
    navigate("/admin")
  }
  const handleUploadImage = async () => {
    if (file && selectedTheater !== "none") {
      const formData = new FormData();
      formData.append("file appended", file);
      formData.append("folderPath", selectedTheater);
      // <---Prod --->
      await axios
        .post(`${awsProdPublicDomain}/admin/img-update`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          alert(res.data.message);
        });
      setFile(null);

      // <---Local --->
      // await axios
      //   .post(`${localhost}/admin/img-update`, formData, {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   })
      //   .then((res) => {
      //     alert(res.data.message);
      //   });
      // setFile(null);
    } else {
      alert("Please select a Theatre type and an image to upload.");
    }
  };

  const handleImageClick = (img) => {
    setEnlargedImage(img);
  };

  const handleCloseEnlarged = () => {
    setEnlargedImage(null);
  };

  useEffect(() => {
    handleGetImages();
  }, [selectedTheater, file]);
  const handleGetImages = async () => {
    await axios
      .get(`${awsProdPublicDomain}/admin/img-get?folderName=${selectedTheater}`)
      .then((res) => {
        setImages(res.data.data);
      });

    // await axios
    //   .get(`${localhost}/admin/img-get?folderName=${selectedTheater}`)
    //   .then((res) => {
    //     setImages(res.data.data);
    //   });
  };
  return accessToken ? (
    <div className="admin-add-img-gallery-container">
      <div className="admin-add-img-gallery">
        <h2>Add Image Gallery</h2>
        <div className="dropdown-container">
          <label htmlFor="roomType" className="dropdown-label">
            Get Images
          </label>
          <select
            onChange={(e) => {
              setSelectedTheater(e.target.value);
            }}
            value={selectedTheater}
            id="roomType"
            className="dropdown-select"
          >
            <option value="none">None</option>
            <option value="couple">Couple Theatre</option>
            <option value="friends">Friends Theatre</option>
            <option value="family">Family Theatre</option>
          </select>
        </div>
        {selectedTheater !== "None" ? (
          <>
            <>
              <div className="file-upload-container">
                <input type="file" onChange={handleFileChange} />
              </div>
              <button className="btn" onClick={handleUploadImage}>
                Upload Images
              </button>
            </>
            <p className="delete-btn">
              If you want to replace any existing photo then use the same name
              and format for the new image otherwise it will get considered as a
              new image
            </p>
          </>
        ) : null}
      </div>
      <br />
      <div className="image-grid">
        {images.map((img, idx) => (
          <div key={idx}>
            <div className="image-item" onClick={() => handleImageClick(img)}>
              <img src={img.imgurl} alt={`Image ${img._id}`} />
            </div>
            <p className="image-url">
              <b>
                Name -{" "}
                {
                  img.imgurl.split(
                    `https://miniflicks-img-bucket.s3.eu-north-1.amazonaws.com/${selectedTheater}/`
                  )[1]
                }
              </b>
            </p>
          </div>
        ))}
      </div>
      {enlargedImage && (
        <div className="enlarged" onClick={handleCloseEnlarged}>
          <img src={enlargedImage.imgurl} alt={`Image ${enlargedImage._id}`} />
        </div>
      )}
    </div>
  ) : (
    (
      <>
      `No Access Token Found `
      <button onClick={handleLogin}>Login</button>
      </>
    )
  );
};

export default AdminAddImgGallery;
