import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Admin = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const awsProdPublicIP = process.env.REACT_APP_AWS_EC2_PUBLIC_IP;
  const awsProdPublicDomain = process.env.REACT_APP_AWS_EC2_DOMAIN;
  const localhost = process.env.REACT_APP_LOCALHOST;

  const handleLogin = (e) => {
    e.preventDefault();

    axios
      .post(
        `${awsProdPublicDomain}/admin/login`,
        { username, password },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        // console.log(res.data.message.admin.accessToken)
        const accessToken = res.data.message.admin.accessToken;
        localStorage.setItem("admin-accessToken", accessToken);
        navigate("/admin/dashboard");
      })
      .catch((error) => {
        console.error("Login error", error);
      });

    // axios
    //   .post(
    //     `${localhost}/admin/login`,
    //     { username, password },
    //     { headers: { "Content-Type": "application/json" } }
    //   )
    //   .then((res) => {
    //     const accessToken = res.data.message.admin.accessToken;
    //     localStorage.setItem("admin-accessToken", accessToken);
    //     navigate("/admin/dashboard");
    //   })
    //   .catch((error) => {
    //     console.error("Login error", error);
    //   });
  };

  return (
    <div className="login">
      <div className="container d-flex align-items-center justify-content-center min-vh-100">
        <div className="row">
          <div className="col-md-12">
            <form
              id="loginform"
              onSubmit={handleLogin}
              className="p-4 p-md-5 border rounded-3 bg-light"
            >
              <h2 className="mb-3 text-center">Admin Login</h2>
              <div className="form-group mb-3">
                <label>Username</label>
                <input
                  type="text"
                  className="form-control"
                  id="UsernameInput"
                  name="UsernameInput"
                  value={username}
                  placeholder="Enter username"
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary w-100">
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
