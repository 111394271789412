import React from "react";
import { testimonial } from "../data/Data";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../css/testimonial.css";

export default function Sliders() {
  const settings = {
    dots: false,
    infinite: true, // Enables infinite scrolling
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true, // Enables automatic movement
    autoplaySpeed: 2000, // Slide changes every 3 seconds
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleButtonClick = () => {
    window.open(
      "https://www.google.com/search?q=google+reviews+miniflicks+bangalore&sca_esv=68041e448ebf4032&sca_upv=1&rlz=1C5CHFA_enIN966IN967&sxsrf=ADLYWIL02G252tgnDSn1xjqQwlY9ZxUvdg%3A1719863970531&ei=ogqDZsuQIOmY4-EP3Y-x-AY&oq=google+reviews+miniflicks+&gs_lp=Egxnd3Mtd2l6LXNlcnAiGmdvb2dsZSByZXZpZXdzIG1pbmlmbGlja3MgKgIIADIFECEYoAEyBRAhGKABMgUQIRifBUiQClCcAVicAXABeACQAQCYAYYBoAGGAaoBAzAuMbgBAcgBAPgBAZgCAaACiwGYAwCIBgGSBwMwLjGgB74D&sclient=gws-wiz-serp#lrd=0x3bae131e0d1e4479:0xcba4ab3250d34b75,1,,,,",
      "_blank"
    );
  };

  return (
    <>
      <div
        className="container testimonial my-5 py-5 bg-dark wow zoomIn"
        data-wow-delay="0.1s"
      >
        <div className="testimonial-carousel py-5">
          <Slider {...settings}>
            {testimonial.map((item, key) => (
              <div
                key={key}
                className="testimonial-item position-relative bg-white rounded overflow-hidden p-4 shadow-sm"
              >
                <p className="testimonial-description">{item.description}</p>
                <div className="d-flex align-items-center mt-3">
                  <div className="ps-3">
                    <h6 className="fw-bold mb-1">{item.name}</h6>
                  </div>
                </div>
                {item.icon}
              </div>
            ))}
          </Slider>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <button className="btn btn-primary btn-lg" onClick={handleButtonClick}>
            Google Reviews
          </button>
        </div>
      </div>
    </>
  );
}
