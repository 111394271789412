import React, { useState } from "react";
import axios from "axios";
import "../../../css/admin.coupon.css";
import { useNavigate } from "react-router-dom";
const AdminAddCouponCode = () => {
  const [copoun, setCouponCode] = useState("");
  const [message, setMessage] = useState("");
  const awsProdPublicIP = process.env.REACT_APP_AWS_EC2_PUBLIC_IP;
  const awsProdPublicDomain = process.env.REACT_APP_AWS_EC2_DOMAIN;
  const localhost = process.env.REACT_APP_LOCALHOST;
  const accessToken = localStorage.getItem("admin-accessToken")
  const navigate = useNavigate()
  const handleLogin  = () => {
    navigate("/admin")
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // <---Prod --->
      await axios
        .post(`${awsProdPublicDomain}/admin/add-coupon-code`, {
          copoun,
        })
        .then((response) => {
          console.log(response.data);
          setMessage("Coupon code added successfully!");
        });
        
        // <---localhost --->
        // await axios
        // .post(`${localhost}/admin/add-coupon-code`, {
        //   copoun,
        // })
        // .then((response) => {
        //   console.log(response.data);
        //   setMessage("Coupon code added successfully!");
        // });
    } catch (error) {
      console.log(error);
      setMessage("Error adding coupon code");
    }
  };

  return (
    accessToken ? 
    (
      <>
      <div className="admin-page-wrapper">
      <div className="admin-coupon-container">
        <h2>Add a Coupon Code</h2>
        <form onSubmit={handleSubmit} className="coupon-form">
          <label htmlFor="couponCode">Coupon Code:</label>
          <input
            type="text"
            id="couponCode"
            value={copoun}
            onChange={(e) => setCouponCode(e.target.value)}
            required
          />
          <button type="submit">Add Coupon</button>
        </form>
        {message && <p className="message">{message}</p>}
      </div>
    </div>
      </>
    )
    :(
      <>
      `No Access Token Found `
      <button onClick={handleLogin}>Login</button>
      </>
    )
  );
};

export default AdminAddCouponCode;
