import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { useNavigate } from "react-router-dom";
import "../../../css/admin.book.css";
import axios from "axios";
import { format } from "date-fns";
import rosepath1 from "../../../rosepath/rosepath1.jpg";
import rosepath2 from "../../../rosepath/rosepath2.jpg";
import rosepath3 from "../../../rosepath/rosepath3.jpg";
import rosepath4 from "../../../rosepath/rosepath4.jpg";
import rosepath5 from "../../../rosepath/rosepath5.jpg";
import rosepath6 from "../../../rosepath/rosepath6.jpg";
import rosepath7 from "../../../rosepath/rosepath7.jpg";
import rosepath8 from "../../../rosepath/rosepath8.jpg";
import rosepath9 from "../../../rosepath/rosepath9.jpg";
import rosepath10 from "../../../rosepath/rosepath10.jpg";

const AdminBookSlot = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [whatsappNo, setWhatsappNo] = useState("");
  const [selectedTheatre, setSelectedTheatre] = useState("");
  const [selectedTheatreType, setSelectedTheatreType] = useState("NA");
  const [selectedTheatrePrice, setSelectedTheatrePrice] = useState({});
  const [selectedDecoration, setSelectedDecoration] = useState("");
  const [ledNameValue, setLedNameValue] = useState("");
  const [ledNumberValue, setLedNumberValue] = useState("");
  const [selectedCake, setSelectedCake] = useState("None");
  const [nameOnCake, setNameOnCake] = useState("");
  const [adult, setAdult] = useState(4);
  const [adultPrice, setAdultPrice] = useState(200);
  const [totalAdultPrice, setTotalAdultPrice] = useState(0);
  const [msg, setMsg] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [secondInputValue, setSecondInputValue] = useState("");
  const [isLedNameChecked, setIsLedNameChecked] = useState(false);
  const [isLedNumberChecked, setIsLedNumberChecked] = useState(false);
  const [showSecondInput, setShowSecondInput] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);

  const localhost = process.env.REACT_APP_LOCALHOST;
  const awsProdPublicDomain = process.env.REACT_APP_AWS_EC2_DOMAIN;
  const accessToken = localStorage.getItem("admin-accessToken");

  const navigate = useNavigate();

  const images = [
    { id: 1, url: rosepath1 },
    { id: 2, url: rosepath2 },
    { id: 3, url: rosepath3 },
    { id: 4, url: rosepath4 },
    { id: 5, url: rosepath5 },
    { id: 6, url: rosepath6 },
    { id: 7, url: rosepath7 },
    { id: 8, url: rosepath8 },
    { id: 9, url: rosepath9 },
    { id: 10, url: rosepath10 },
  ];

  const timeSlots = {
    "Couple Theatre": [
      "09:00 AM - 11:30 PM",
      "12:15 PM - 02:45 PM",
      "03:30 PM - 06:00 PM",
      "06:45 PM - 09:15 PM",
      "10:00 PM - 12:30 AM",
    ],
    "Friends Theatre": [
      "09:30 AM - 12:00 PM",
      "12:45 PM - 03:15 PM",
      "04:00 PM - 06:30 PM",
      "07:15 PM - 09:45 PM",
      "10:30 PM - 01:00 AM",
    ],
    "Family Theatre": [
      "10:00 AM - 01:00 PM",
      "02:00 PM - 05:00 PM",
      "06:00 PM - 09:00 PM",
      "10:00 PM - 01:00 AM",
    ],
  };

  const handleImageSelect = (id) => {
    setSelectedImageId(id);
    setIsModalOpen(false);
  };

  const handleSelectedDate = (date) => {
    setSelectedDate(date);
  };

  const handleSlotAvailability = async () => {
    if (!selectedDate || !selectedTime || !selectedTheatre) {
      alert("Date and Time are Mandatory");
      return;
    }
    try {
      // const res = await axios.post(`${localhost}/checkdatetimetheater`, {
      //   selectedDate: format(selectedDate, "dd/MM/yyyy"),
      //   times: timeSlots[selectedTheatre],
      //   selectedTheatre,
      // });

      const res = await axios.post(
        `${awsProdPublicDomain}/checkdatetimetheater`,
        {
          selectedDate: format(selectedDate, "dd/MM/yyyy"),
          times: timeSlots[selectedTheatre],
          selectedTheatre,
        }
      );
      const lockedSlots = res.data.data.lockedSlots;
      const isSlotBooked = lockedSlots.includes(selectedTime);
      setMsg(isSlotBooked ? "Slot is Booked!" : "Slot is free");
    } catch (err) {
      console.log("Error", err);
    }
  };

  const handleTheatrePrice = async (selectedTheatre) => {
    if (!selectedTheatre) {
      alert("Select Theatre Is required");
      return;
    }
    try {
      // const res = await axios.post(`${localhost}/admin/packagetype-get`, {
      //   selectedTheatre,
      // });

      const res = await axios.post(
        `${awsProdPublicDomain}/admin/packagetype-get`,
        {
          selectedTheatre,
        }
      );
      setSelectedTheatrePrice(res.data.data);
    } catch (err) {
      console.log("Error", err);
    }
  };

  const handleTheatreType = (key) => {
    setSelectedTheatreType(key);
    if (key === "ULTRA_PREMIUM") {
      setIsModalOpen(true);
    } else {
      setSelectedImageId(null);
    }
  };

  const handleTheatreChange = (e) => {
    const theatre = e.target.value;
    setSelectedTheatre(theatre);
    setMsg("");
    setSelectedTime(null);
    setSelectedTheatreType("NA");
    setTotalAdultPrice(0);
  };

  const handleDecorationChange = (e) => {
    const decoration = e.target.value;
    setSelectedDecoration(decoration);
    setShowSecondInput(
      decoration === "Bride to be" ||
        decoration === "Farewell" ||
        decoration === "Congratulations" ||
        decoration === "Groom to be" ||
        decoration === "Anniversary" ||
        decoration === "Romantic Date" ||
        decoration === "Marriage Proposal"
    );
  };

  const handleAdult = (e) => {
    e.preventDefault();
    if (e.target.id === "addAdult") {
      if (
        (selectedTheatre === "Friends Theatre" && adult < 21) ||
        (selectedTheatre === "Family Theatre" && adult < 21)
      ) {
        setAdult(adult + 1);
      }
    }
    if (e.target.id === "removeAdult" && adult > 4) {
      setAdult(adult - 1);
    }
  };

  const calculateTotalAdultPrice = (adultCount) => {
    const extraAdults = adultCount - 4;
    return extraAdults > 0 ? extraAdults * adultPrice : 0;
  };

  const calculateLedNamePrice = (ledName) => {
    if (!ledName) return 0;
    return ledName.length <= 8 ? 299 : 299 + (ledName.length - 8) * 30;
  };

  const calculateLedNumberPrice = (ledNumber) => {
    if (!ledNumber) return 0;
    return ledNumber.length > 0 ? 99 : 0;
  };

  const handleLogin = () => {
    navigate("/admin");
  };

  const handleTotalAmount = () => {
    const nonzeroPriceCakes = [
      "Rasmalai",
      "FreshFruit",
      "ChocolateTruffle",
      "ChocolateAlmond",
    ];
    const cakePrice = nonzeroPriceCakes.includes(selectedCake)
      ? 199
      : selectedCake === "ChocolatePinata"
      ? 249
      : 0;
    const ledNamePrice = calculateLedNamePrice(ledNameValue);
    const ledNumberPrice = calculateLedNumberPrice(ledNumberValue);
    const rosePathCost = selectedImageId ? 199 : 0;

    // console.log(
    //   Number(totalAdultPrice),
    //   Number(selectedTheatrePrice[selectedTheatreType]),
    //   selectedTheatre === "BASIC" ? 0 : (cakePrice),
    //   Number(ledNamePrice),
    //   Number(ledNumberPrice),
    //   Number(rosePathCost)
    // );
    return (
      Number(totalAdultPrice) +
      Number(selectedTheatrePrice[selectedTheatreType]) +
      Number(cakePrice) +
      Number(ledNamePrice) +
      Number(ledNumberPrice) +
      Number(rosePathCost)
    );
  };

  useEffect(() => {
    setTotalAdultPrice(calculateTotalAdultPrice(adult));
  }, [adult]);

  useEffect(() => {
    setAdult(4);
    setTotalAdultPrice(0);
    setSelectedTheatreType("NA");
    setMsg("");
    setSelectedTime(null);
  }, [selectedTheatre]);

  useEffect(() => {
    if (msg === "Slot is free") {
      handleTheatrePrice(selectedTheatre);
    }
  }, [msg, selectedTheatre]);

  const handleConfirmBook = async () => {
    // await axios
    //   .post(`${localhost}/admin/book-slot`, {
    //     selectedDate: format(selectedDate, "dd/MM/yyyy"),
    //     selectedTime,
    //     username,
    //     email,
    //     whatsappNo,
    //     selectedTheatre,
    //     selectedTheatreType,
    //     selectedTheatrePrice: selectedTheatrePrice[selectedTheatreType],
    //     rosePathDesgin: selectedImageId ? selectedImageId : "NA",
    //     extraAdults: adult - 4,
    //     totalAdultPrice,
    //     selectedLedNameDecoration: isLedNameChecked ? "Yes" : "NA",
    //     selectedLedNameDecorationValue: isLedNameChecked ? ledNameValue : "NA",
    //     selectedLedNumberDecoration: isLedNumberChecked ? "Yes" : "NA",
    //     selectedLedNumberDecorationValue: isLedNumberChecked
    //       ? ledNumberValue
    //       : "NA",
    //     selectedDecorationType: selectedDecoration,
    //     selectedDecorationDetails: showSecondInput
    //       ? `${inputValue}, ${secondInputValue}`
    //       : inputValue,
    //     selectedCake: selectedTheatreType === "BASIC" ? "NA" : selectedCake,
    //     nameonCake: selectedTheatreType === "BASIC" ? "NA" : nameOnCake,
    //     selectedCakePrice: cakePrices[selectedCake] || 0,
    //     totalAmount: handleTotalAmount(),
    //   })
    //   .then((res) => {
    //     console.log(res.data);
    //     alert(res.data.data);
    //     window.location.reload();
    //   });

    // <---prod---->
    await axios
      .post(`${awsProdPublicDomain}/admin/book-slot`, {
        selectedDate: format(selectedDate, "dd/MM/yyyy"),
        selectedTime,
        username,
        email,
        whatsappNo,
        selectedTheatre,
        selectedTheatreType,
        selectedTheatrePrice: selectedTheatrePrice[selectedTheatreType],
        rosePathDesgin: selectedImageId ? selectedImageId : "NA",
        extraAdults: adult - 4,
        totalAdultPrice,
        selectedLedNameDecoration: isLedNameChecked ? "Yes" : "NA",
        selectedLedNameDecorationValue: isLedNameChecked ? ledNameValue : "NA",
        selectedLedNumberDecoration: isLedNumberChecked ? "Yes" : "NA",
        selectedLedNumberDecorationValue: isLedNumberChecked
          ? ledNumberValue
          : "NA",
        selectedDecorationType: selectedDecoration,
        selectedDecorationDetails: showSecondInput
          ? `${inputValue}, ${secondInputValue}`
          : inputValue,
        selectedCake: selectedTheatreType === "BASIC" ? "NA" : selectedCake || "NA",
        nameonCake: selectedTheatreType === "BASIC" ? "NA" : nameOnCake || "NA",
        selectedCakePrice: cakePrices[selectedCake] || 0,
        totalAmount: handleTotalAmount(),
      })
      .then((res) => {
        console.log(res.data);
        alert(res.data.data);
        window.location.reload();
      });
  };

  const cakePrices = {
    ButterScotch: 0,
    Chocolate: 0,
    BlackForest: 0,
    RedVelvetRound: 0,
    RedVelvetHeart: 0,
    Pineapple: 0,
    Blueberry: 0,
    MangoDelight: 0,
    DeathByChocolate: 0,
    IrishCoffee: 0,
    BelgianChocolate: 0,
    Strawberry: 0,
    HoneyAlmond: 0,
    WhiteForest:0,
    Rasmalai: 199,
    FreshFruit: 199,
    ChocolateTruffle: 199,
    ChocolateAlmond: 199,
    Customized:199,
    DarkChocolateHeartPinata: 249,
  };

  const getPlaceholders = (label) => {
    switch (label) {
      case "Birthday":
        return ["Enter Name"];
      case "Bride to be":
        return ["Enter Bride's Name", "Enter Other Details"];
      case "Farewell":
        return ["Enter Name", "Enter Other Details"];
      case "Congratulations":
        return ["Enter Name", "Enter Other Details"];
      case "Baby Shower":
        return ["Enter Baby's Name"];
      case "Groom to be":
        return ["Enter Groom's Name", "Enter Other Details"];
      case "Anniversary":
        return ["Enter Partner's Name", "Enter Other Details"];
      case "Romantic Date":
        return ["Enter Partner's Name", "Enter Other Details"];
      case "Marriage Proposal":
        return ["Enter Partner's Name", "Enter Other Details"];
      case "Others":
        return ["Enter Occasion Name", "Enter Other Details"];
      default:
        return ["Enter details"];
    }
  };

  return accessToken ? (
    <>
      <div className="admin-book-container">
        <div className="admin-book-content container mt-5">
          <h2 className="admin-book-title">Book Slot</h2>
          <div className="form-group admin-book-form-group">
            <label htmlFor="selectedTheater">Theater</label>
            <select
              id="selectedTheater"
              className="form-control admin-book-custom-select"
              value={selectedTheatre}
              onChange={handleTheatreChange}
              required
            >
              <option value="">Select Theater</option>
              <option value="Couple Theatre">Couple Theatre</option>
              <option value="Friends Theatre">Friends Theatre</option>
              <option value="Family Theatre">Family Theatre</option>
            </select>
          </div>

          {!selectedTheatre ? (
            "Please Select theatre"
          ) : (
            <>
              <Calendar
                minDate={new Date()}
                onChange={handleSelectedDate}
                value={selectedDate}
                className="mb-3 admin-book-calendar"
              />
              <p className="admin-book-text mb-3">
                <b>Selected Date: {format(selectedDate, "dd/MM/yyyy")}</b>
              </p>
              <div className="form-group admin-book-form-group">
                Time Slots:
                {selectedTheatre &&
                  timeSlots[selectedTheatre].map((time) => (
                    <button
                      key={time}
                      onClick={() => setSelectedTime(time)}
                      className={`btn btn-time-slot ${
                        selectedTime === time ? "selected" : ""
                      }`}
                    >
                      {time}
                    </button>
                  ))}
                <b>
                  <p className="admin-book-text mb-3">
                    Selected Time : {selectedTime}
                  </p>
                </b>
              </div>
              <button
                className="admin-book-btn"
                onClick={handleSlotAvailability}
              >
                Check Slot
              </button>
              {<p className="admin-book-msg">{msg}</p>}

              {msg === "Slot is free" && (
                <>
                  {selectedTheatre !== "Couple Theatre" ? (
                    <div className="admin-book-extra col-lg-3">
                      <p className="label">Extra person's</p>
                      {selectedTheatre === "Friends Theatre" ? (
                        <>
                          <div>
                            <button
                              id="removeAdult"
                              disabled={adult <= 4}
                              onClick={handleAdult}
                              className="btn"
                            >
                              -
                            </button>
                            <span className="adult-value">{adult}</span>{" "}
                            <button
                              id="addAdult"
                              disabled={adult >= 20}
                              onClick={handleAdult}
                              className="btn"
                            >
                              +
                            </button>
                            <p className="label">
                              Adult Price (₹200 per head) i.e. {adult - 4} X{" "}
                              {adultPrice} = ₹{totalAdultPrice}
                            </p>
                          </div>
                        </>
                      ) : selectedTheatre === "Family Theatre" ? (
                        <>
                          <div>
                            <button
                              id="removeAdult"
                              disabled={adult <= 4}
                              onClick={handleAdult}
                              className="btn"
                            >
                              -
                            </button>
                            <span className="adult-value">{adult}</span>{" "}
                            <button
                              id="addAdult"
                              disabled={adult >= 20}
                              onClick={handleAdult}
                              className="btn"
                            >
                              +
                            </button>
                            <p className="label">
                              Adult Price (₹200 per head) i.e. {adult - 4} X{" "}
                              {adultPrice} = ₹{totalAdultPrice}
                            </p>
                          </div>
                        </>
                      ) : null}
                    </div>
                  ) : null}
                  {Object.keys(selectedTheatrePrice).length > 0 && (
                    <ul>
                      {Object.entries(selectedTheatrePrice).map(
                        ([key, value]) =>
                          key && value ? (
                            <li key={key}>
                              {key}: {value}
                              <button
                                className={`btn btn-time-slot`}
                                onClick={() => handleTheatreType(key)}
                              >
                                Select
                              </button>
                            </li>
                          ) : (
                            "Error in fetching package"
                          )
                      )}
                    </ul>
                  )}
                  {selectedTheatreType === "NA" ? null : (
                    <p>
                      Selected Theatre Type: {selectedTheatreType}
                      <br />
                      Price: {selectedTheatrePrice[selectedTheatreType]}
                    </p>
                  )}

                  <div className="form-group admin-book-form-group">
                    <label htmlFor="username">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      placeholder="Enter your name"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group admin-book-form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group admin-book-form-group">
                    <label htmlFor="whatsappNo">WhatsApp Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="whatsappNo"
                      placeholder="Enter your WhatsApp number"
                      value={whatsappNo}
                      onChange={(e) => setWhatsappNo(e.target.value)}
                      required
                    />
                  </div>

                  <div className="form-group admin-book-form-group">
                    <label htmlFor="decoration">Decoration Type</label>
                    <select
                      name="decoration"
                      id="decoration"
                      className="form-control admin-book-custom-select"
                      value={selectedDecoration}
                      onChange={handleDecorationChange}
                      required
                    >
                      <option value="">Select</option>
                      <option value="Birthday">Birthday</option>
                      <option value="Anniversary">Anniversary</option>
                      <option value="Romantic Date">Romantic Date</option>
                      <option value="Marriage Proposal">
                        Marriage Proposal
                      </option>
                      <option value="Bride to be">Bride to be</option>
                      <option value="Groom to be">Groom to be</option>
                      <option value="Farewell">Farewell</option>
                      <option value="Congratulations">Congratulations</option>
                      <option value="Baby Shower">Baby Shower</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  {selectedDecoration && (
                    <div className="input-fields">
                      <input
                        type="text"
                        placeholder={getPlaceholders(selectedDecoration)[0]}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        required
                      />
                      {showSecondInput && (
                        <input
                          type="text"
                          placeholder={getPlaceholders(selectedDecoration)[1]}
                          value={secondInputValue}
                          onChange={(e) => setSecondInputValue(e.target.value)}
                          required
                        />
                      )}
                    </div>
                  )}
                  <label htmlFor="selectedTheater">Special Addons</label>
                  <br />
                  <input
                    type="checkbox"
                    name="ledName"
                    checked={isLedNameChecked}
                    onChange={() => {
                      setIsLedNameChecked(!isLedNameChecked);
                      if (!isLedNameChecked) {
                        setLedNameValue(""); // Clear the input when unchecked
                      }
                    }}
                  />
                  <label htmlFor="ledName">Led Name</label>
                  <input
                    name="ledNameValue"
                    type="text"
                    placeholder="Led Name Value"
                    value={ledNameValue}
                    onChange={(e) => setLedNameValue(e.target.value)}
                    disabled={!isLedNameChecked}
                  />
                  <br />
                  <input
                    type="checkbox"
                    name="ledNumber"
                    checked={isLedNumberChecked}
                    onChange={() => {
                      setIsLedNumberChecked(!isLedNumberChecked);
                      if (!isLedNumberChecked) {
                        setLedNumberValue(""); // Clear the input when unchecked
                      }
                    }}
                  />
                  <label htmlFor="ledNumber">Led Number</label>
                  <input
                    name="ledNumberValue"
                    type="text"
                    placeholder="Led Number Value"
                    value={ledNumberValue}
                    onChange={(e) => setLedNumberValue(e.target.value)}
                    disabled={!isLedNumberChecked}
                  />
                  {selectedTheatreType == "BASIC" ? null : (
                    <>
                      <div className="form-group admin-book-form-group">
                        <label htmlFor="cake">Cake</label>
                        <select
                          name="cake"
                          id="cake"
                          className="form-control admin-book-custom-select"
                          value={selectedCake}
                          onChange={(e) => setSelectedCake(e.target.value)}
                          required
                        >
                          <option value="Select">Select</option>
                          <option value="BlackForest">BlackForest- ₹0</option>
                          <option value="RedVelvetRound">
                            RedVelvetRound - ₹0
                          </option>
                          <option value="RedVelvetHeart">
                            RedVelvetHeart - ₹0
                          </option>
                          <option value="Pineapple">Pineapple - ₹0</option>
                          <option value="MangoDelight">
                            Mango Delight - ₹0
                          </option>
                          <option value="Blueberry">Blueberry - ₹0</option>
                          <option value="ButterScotch">Butter Scotch - ₹0</option>
                          <option value="WhiteForest">White Forest - ₹0</option>
                          <option value="Deathbychocolate">
                            Deathbychocolate - ₹0
                          </option>
                          <option value="Irishcoffeecake">
                            Irish coffee - ₹0
                          </option>
                          <option value="Belgianchocolate">
                            Belgian chocolate cake - ₹0
                          </option>
                          <option value="Strawberry">Strawberry - ₹0</option>
                          <option value="HoneyAlmond">Honey Almond - ₹0</option>
                          <option value="Chocolate">Chocolate - ₹0</option>
                          <option value="Strawberry">Strawberry - ₹0</option>
                          <option value="Rasmalai">Rasmalai - ₹199</option>
                          <option value="Customized">Customized - ₹199</option>
                          <option value="FreshFruit">Fresh Fruit - ₹199</option>
                          <option value="ChocolateTruffle">
                            Chocolate Truffle - ₹199
                          </option>
                          <option value="ChocolateAlmond">
                            Chocolate Almond - ₹199
                          </option>
                          <option value="ChocolatePinata">
                            Chocolate Pinata - ₹249
                          </option>
                        </select>
                      </div>
                      <div className="form-group admin-book-form-group">
                        <label htmlFor="nameOnCake">Name on Cake</label>
                        <input
                          type="text"
                          className="form-control"
                          id="nameOnCake"
                          placeholder="Enter name on cake"
                          value={nameOnCake}
                          onChange={(e) => setNameOnCake(e.target.value)}
                          required
                        />
                      </div>
                    </>
                  )}
                  <p>Total Amount : {handleTotalAmount()}</p>
                  {!username ||
                  !email ||
                  !whatsappNo ||
                  !selectedCake || !selectedDecoration || !nameOnCake? null : (
                    <button
                      className="admin-book-btn"
                      onClick={handleConfirmBook}
                    >
                      Confirm Book!
                    </button>
                  )}
                </>
              )}
            </>
          )}
        </div>
        {/* {isModalOpen && (
          <div className="booking-progress-backdrop">
            <div className="booking-progress-modal">
              <h2>Select Rose Path Design</h2>
              <div className="rosepath-images">
                {images.map((image) => (
                  <img
                    key={image.id}
                    src={image.url}
                    alt={`Rose Path Design ${image.id}`}
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "cover",
                      borderRadius: "4px",
                      cursor: "pointer",
                      transition: "transform 0.2s",
                      border:
                        selectedImageId === image.id
                          ? "2px solid #007bff"
                          : "none",
                    }}
                    onClick={() => handleImageSelect(image.id)}
                    onMouseOver={(e) =>
                      (e.target.style.transform = "scale(1.05)")
                    }
                    onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
                  />
                ))}
              </div>
              <button
                className="admin-book-btn"
                onClick={() => setIsModalOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        )} */}
      </div>
    </>
  ) : (
    <>
      `No Access Token Found `<button onClick={handleLogin}>Login</button>
    </>
  );
};

export default AdminBookSlot;
