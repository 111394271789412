import React , {useEffect}from "react";
import "../css/refundpolicy.css";
import { FaRegCheckCircle, FaTimesCircle } from "react-icons/fa";

const RefundPolicy = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  })
  return (
    <div className="refund-policy-container">
      <h2 className="refund-policy-title">Refund Policy</h2>
      <ul className="refund-policy-list">
        <li className="refund-policy-item" data-wow-delay="0.1s">
          <FaRegCheckCircle className="policy-icon" />
          MiniFlicks Private Theater’s money refund policy is designed to provide
          customers with flexibility and peace of mind. If a customer needs to
          cancel their private theater booking, MiniFlicks offers a refund
          according to the following guidelines:
        </li>
        <li className="refund-policy-item" data-wow-delay="0.2s">
          <FaRegCheckCircle className="policy-icon" />
          Our team collects the Advance amount of 999/- for reservation of time
          slot.
        </li>
        <li className="refund-policy-item" data-wow-delay="0.3s">
          <FaRegCheckCircle className="policy-icon" />
          This amount is fully refundable (except for convenience charges of
          payment gateway, if any).
        </li>
        <li className="refund-policy-item" data-wow-delay="0.4s">
          <FaRegCheckCircle className="policy-icon" />
          Customers must inform us about their cancellation or reschedule before
          72 hours of their booking slot. If they inform us, we will initiate a
          refund within 24 hours and take a maximum of 5-7 days to be completed.
        </li>
        <li className="refund-policy-item" data-wow-delay="0.5s">
          <FaTimesCircle className="policy-icon" />
          MiniFlicks Private Theatre does not offer refunds for cancellations
          made less than 72 hours in advance of the scheduled screening time.
        </li>
        <li className="refund-policy-item" data-wow-delay="0.6s">
          <FaTimesCircle className="policy-icon" />
          No Refunds for bookings scheduled on Friday, Saturday, or Sunday
          slots.
        </li>
        <li className="refund-policy-item" data-wow-delay="0.7s">
          <FaTimesCircle className="policy-icon" />
          Also, there will be no refunds in case of rescheduled bookings.
        </li>
        <li className="refund-policy-item" data-wow-delay="0.8s">
          <FaRegCheckCircle className="policy-icon" />
          MiniFlicks understands that plans can change, and they aim to
          accommodate their customers to the best of their ability. This refund
          policy reflects their commitment to providing a positive and flexible
          experience for those who book private theatre screenings.
        </li>
      </ul>
    </div>
  );
};

export default RefundPolicy;
