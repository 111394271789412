import couplecover from "../../cover/couplecover.jpeg";
import friendscover from "../../cover/friendscover.jpeg";
import familycover from "../../cover/familycover.jpeg";
import familyphoto from "../../cover/familyphoto.jpeg";
export const navList = [
  {
    id: 1,
    path: "/",
    text: "Home",
  },
  {
    id: 2,
    path: "/about",
    text: "About",
  },
  {
    id: 5,
    path: "/gallery",
    text: "Gallery",
  },
  {
    id: 6,
    path: "/contact",
    text: "Contact",
  },
  {
    id: 7,
    path: "/refund-policy",
    text: "Refund Policy",
  },
  {
    id: 8,
    path: "/tnc",
    text: "Terms and Conditions",
  },
  {
    id: 9,
    path: "/testimonial",
    text: "Testimonial",
  },
  {
    id: 9,
    path: "/faq",
    text: "FAQ",
  },
];
export const socialIcons = [
  {
    icon: <i className="fab fa-facebook-f"></i>,
    url: "https://www.facebook.com/profile.php?id=61554669865343&mibextid=rS40aB7S9Ucbxw6v",
    isExternal: true,
  },

  {
    icon: <i className="fab fa-instagram"></i>,
    url: "https://www.instagram.com/miniflicks_marathahalli?utm_source=qr&igsh=MW8xcmgwc2c3YzdxaA==",
    isExternal: true,
  },
  {
    icon: <i className="fab fa-youtube"></i>,
    url: "https://youtube.com/@miniflicks_marathahalli?si=LLbkXQdF3A4Y4Xsp",
    isExternal: true,
  },
];

export const carouselData = [
  {
    img: couplecover,
    title: "Book MiniFlicks for an unforgettable experience!",
    subtitle: "India's Number One Private Theatre Celebration Space ",
    body: "Couple Theatre",
    id: "Couple Theatre",
  },
  {
    img: friendscover,
    title: "Book MiniFlicks for an unforgettable experience!",
    subtitle: "India's Number One Private Theatre Celebration Space ",
    body: "Friends Theatre",
    id: "Friends Theatre",
  },
  {
    img: familyphoto,
    title: "Book MiniFlicks for an unforgettable experience!",
    subtitle: "India's Number One Private Theatre Celebration Space ",
    body: "Family Theatre",
    id: "Family Theatre",
  },
];
export const about = [
  {
    icon: <i class="fa fa-hotel fa-2x text-primary mb-2"></i>,
    text: "Theaters",
    count: "3",
  },
  {
    icon: <i class="fa fa-users fa-2x text-primary mb-2"></i>,
    text: "Staffs",
    count: "10+",
  },
  {
    icon: <i class="fa fa-users-cog fa-2x text-primary mb-2"></i>,
    text: "Clients",
    count: "125+",
  },
];

export const services = [
  {
    icon: <i class="fa fa-hotel fa-2x text-primary"></i>,
    name: "Family Theaters",
    discription: "Contrary to popular belief, ipsum is not simply random.",
  },
  {
    icon: <i class="fa fa-utensils fa-2x text-primary"></i>,
    name: "Friend's Theater",
    discription: "Contrary to popular belief, ipsum is not simply random.",
  },
  {
    icon: <i class="fa fa-spa fa-2x text-primary"></i>,
    name: "Couple Theater",
    discription: "Contrary to popular belief, ipsum is not simply random.",
  },
  {
    icon: <i class="fa fa-swimmer fa-2x text-primary"></i>,
    name: "Cakes",
    discription: "Contrary to popular belief, ipsum is not simply random.",
  },
  {
    icon: <i class="fa fa-swimmer fa-2x text-primary"></i>,
    name: "Food & Beverages",
    discription: "Contrary to popular belief, ipsum is not simply random.",
  },
  {
    icon: <i class="fa fa-swimmer fa-2x text-primary"></i>,
    name: "Decoration & Gifts",
    discription: "Contrary to popular belief, ipsum is not simply random.",
  },
];
export const team = [
  {
    image: "../assets/img/team-1.jpg",
    name: "Chandra Mouli Reddy Vangumalla",
    designation: "Designation",
  },
  {
    image: "../assets/img/team-2.jpg",
    name: "Veeranjaneya Reddy Yagnam",
    designation: "Designation",
  },
  {
    image: "../assets/img/team-3.jpg",
    name: "Thej Kumar Manchi",
    designation: "Designation",
  },
  {
    image: "../assets/img/team-3.jpg",
    name: "M Kailash",
    designation: "Designation",
  },
];

export const footerItem = [
  {
    id: 1,
    header: "Company",
    UnitItem: [
      {
        name: "About Us",
      },
      {
        name: "Contact Us",
      },
      {
        name: "Privacy Policy",
      },
      {
        name: "Terms & Condition",
      },
      {
        name: "Refund Policy",
      },
    ],
  },
  {
    id: 2,
    header: "Services",
    UnitItem: [
      {
        name: "Family Theater",
      },
      {
        name: "Couple Theater",
      },
      {
        name: "Friend's Theater",
      },
      {
        name: "Event & Party",
      },
    ],
  },
];

export const footerContact = [
  {
    icon: <i className="fa fa-map-marker-alt"></i>,
    name: "THE SUMMIT # 13, 1st 'A' Cross, Anantharama Reddy Layout, Outer Ring Road, Chinnappanahalli, Marathahalli, Bengaluru-560037",
  },
  {
    icon: <i className="fa fa-phone-alt "></i>,
    name: "+91 9019162002",
  },
  {
    name: "+91 8143115899",
  },
  {
    icon: <i className="fa fa-envelope "></i>,
    name: "miniflicksprivatetheatres@gmail.com",
  },
];

export const contact = [
  {
    icon: <i class="fa fa-envelope-open text-primary me-2"></i>,
    title: "Booking",
    email: "miniflicksprivatetheatres@gmail.com",
  },
  {
    icon: <i class="fa fa-phone text-primary me-2"></i>,
    title: "Contact No",
    email: "+91 9019162002 /  +91 8143115899",
  },
];
export const testimonial = [
  {
    description:
      "MiniFlicks truly delivered an unforgettable anniversary celebration for us on our anniversary day 20th March 2024.The owners' warm and welcoming demeanor added to the charm of our experience.The high-quality sound system and screen enhanced our enjoyment of the event, while the stunning decorations created a magical atmosphere, Despite our last-minute cake order, they graciously arranged it, even late at night.We were all thrilled by the special touches like fog and rose petals entry, offered at a minimal cost. Moreover, the affordability of the three-hour package, inclusive of all facilities, surpassed our expectations. Conveniently located near Jeevika hospital, accessibility was never an issue.Highly recommend MiniFlicks for any special occasion!",
    name: "Ratheesh CN",
    profession: "Engineer",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-1.jpg",
  },
  {
    description:
      "The private party theatre experience exceeded expectations. Management was impeccable, ensuring a seamless event. The ambience enhanced the overall enjoyment. Miniflicks' organized platform made planning effortless. Perfect for surprise parties and various occasions. Customized decorations were provided within a budget-friendly range, adding a personal touch to the festivities. Highly recommended for unforgettable celebrations",
    name: "Sameer shaik",
    profession: "Entreprenuer",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-2.jpg",
  },
  {
    description:
      " It's a wonderful experience in MiniFlicks private Theatre. Mainly Staff is so good , Friendly and very cooperative. Theater decoration and others thing is soo good in MiniFlicks private Theatre. If you want to experience the good private theatre world with Dolby sounds I can suggest you MiniFlicks private Theatre",
    name: "Reshu Thupaki",
    profession: "Student",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-3.jpg",
  },
  {
    description:
      "have been there and had a booking of family theatre. It has been a wonderful experience and almost like a original theatre experience with audio installed. It has dolby atmos surround and awesome bass ",
    name: "Komal Sharma",
    profession: "HouseWife",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-3.jpg",
  },
  {
    description:
      "have been there and had a booking of family theatre. It has been a wonderful experience and almost like a original theatre experience with audio installed. It has dolby atmos surround and awesome bass ",
    name: "Jayaprakash",
    profession: "HouseWife",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-3.jpg",
  },
  {
    description:
      "I have been there and had a booked couple private theatre. It has been a wonderful experience and almost like a original theatre experience with audio installed. They are also providing add ons like decoration, cakes and so on . Prices are very affordable. Staffs are also very friendly.I liked it a lot and njoyed well. Thank you miniflicks team🙂",
    name: "Hemalatha P",
    profession: "HouseWife",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-3.jpg",
  },
  {
    description:
      "I wanted to express my gratitude for the incredible private theater experience. The ambiance was superb, creating the perfect atmosphere for enjoying the performance. The seating was exceptionally comfortable, allowing me to fully relax and immerse myself in the show. The sound quality was top-notch, truly enhancing the audio experience. Overall, it was a memorable and enjoyable experience that I look forward to repeating in the future. Thank you for providing such a fantastic entertainment option",
    name: "	Manish Kumar  ",
    profession: "HouseWife",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-3.jpg",
  },
  {
    description:
      "We recently celebrated my mom's 50th birthday, and it was an absolute delight! The staff were incredibly warm and welcoming, and the decorations added such a special touch to the celebration. Not to mention, their collection of showpieces was truly impressive, adding an extra layer of charm to the ambiance. It was truly a wonderful experience",
    name: "	Kavya kamisetty",
    profession: "HouseWife",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-3.jpg",
  },
  {
    description:
      "Recently celebrated my birthday and it was top-notch. Thanks for the amazing birthday set up team. Service was great, staff were so helpful and good food aswell. Highly recommended!!",
    name: "	Deepika GN",
    profession: "HouseWife",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-3.jpg",
  },
  {
    description:
      "I booked a surprise birthday party for my husband. The decoration and ambience were very good, and they quoted very reasonable price. The staffs were very polite and service was excellent. The movie experience was also enjoyable with perfect audio and video arrangements, and it was indeed like watching a movie in a theatre",
    name: "	Vidhya Karanthamalai",
    profession: "HouseWife",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-3.jpg",
  },
  {
    description:
      "I went to the place with limited expectations. But once I entered the private theatre, I was flabbergasted. The ambience was really awesome and it was a memorable experience for a lifetime. The prices are very affordable and the service was too good. I highly recommend planning any celebration in this venue. It's worth every penny",
    name: "Sreenivasan sathiyamoorthy",
    profession: "HouseWife",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-3.jpg",
  },
];

export const TheaterItems = [
  {
    img: couplecover,
    name: "Couple Theater",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Enjoy a romantic getaway in our cozy Couple Theater, fun-filled stay in our spacious perfect for a private, intimate experience.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
  },

  {
    img: friendscover,

    name: "Friend's Theater",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Gather your friends and dive into a fun-filled stay in our spacious Friends Theater, designed for great memories.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
  },
  {
    img: familycover,
    name: "Family Theater",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Create lasting family memories in our comfortable Family Theater, offering ample space and modern amenities",
    yellowbtn: "View Detail",
    darkbtn: "book now",
  },
];

// export const facility = [
//   {
//     icon: <i class="fa fa-bed text-primary me-2"></i>,
//     quantity: 3,
//     facility: "bed",
//   },
//   {
//     icon: <i class="fa fa-bath text-primary me-2"></i>,
//     quantity: 2,
//     facility: "bath",
//   },
//   {
//     icon: <i class="fa fa-wifi text-primary me-2"></i>,
//     facility: "Wifi",
//   },
// ];
