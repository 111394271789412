import React  from "react";
import AdminHome from "../Home/Home";
import { useNavigate } from "react-router-dom";
const AdminDashboard = () => {
  const accessToken = localStorage.getItem("admin-accessToken")
  const navigate = useNavigate()
  const handleLogin  = () => {
    navigate("/admin")
  }
  return (
    accessToken ? 
    (<div className="grid-container">
      <AdminHome />
    </div>)
    : (
      <>
      `No Access Token Found `
      <button onClick={handleLogin}>Login</button>
      </>
    )
  );
};

export default AdminDashboard;
