import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../../css/admin.customer.order.css";
import { useNavigate } from "react-router-dom";
const AdminCustomerOrders = () => {
  const [totalOrderDetails, setTotalOrderDetails] = useState([]);
  const awsProdPublicIP = process.env.REACT_APP_AWS_EC2_PUBLIC_IP;
  const awsProdPublicDomain = process.env.REACT_APP_AWS_EC2_DOMAIN;
  const localhost = process.env.REACT_APP_LOCALHOST;
  const accessToken = localStorage.getItem("admin-accessToken")

  const handleDelete = async (deletedOrderId) => {
    try {
      // <---Prod --->
      await axios.delete(
        `${awsProdPublicDomain}/admin/order/${deletedOrderId}`
      );
      setTotalOrderDetails(
        totalOrderDetails.filter((order) => order._id !== deletedOrderId)
      );

      // <---Local --->
      // axios.delete(`${localhost}/admin/order/${deletedOrderId}`);
      // setTotalOrderDetails(
      //   totalOrderDetails.filter((order) => order._id !== deletedOrderId)
      // );
    } catch (error) {
      console.error("There was an error deleting the order!", error);
    }
  };
  const navigate = useNavigate()
  const handleLogin  = () => {
    navigate("/admin")
  }
  useEffect(() => {
    // // <--- Prod --->
    axios
      .get(`${awsProdPublicDomain}/admin/customers`)
    .then((res) => {
      setTotalOrderDetails(res.data.message);
    })
     .catch((err) => console.log(err));

    // <--- localhost --->
    // axios
    //   .get(`${localhost}/admin/customers`)
    //   .then((res) => {
    //     setTotalOrderDetails(res.data.message);
    //   })
    //   .catch((err) => console.log(err));
  }, []);

  return (
    accessToken ? 
    (
      <>
      <div className="customer-booked-body">
      <div className="customer-booked-container customer-booked-mt-5 customer-booked-px-2">
        <div className="customer-booked-table-responsive">
          <table className="customer-booked-table customer-booked-table-borderless">
            <thead>
              <tr className="customer-booked-bg-light">
                <th scope="col">Transaction ID</th>
                <th scope="col">Status</th>
                <th scope="col">User</th>
                <th scope="col">Email</th>
                <th scope="col">WhatsApp No</th>
                <th scope="col">Selected Date</th>
                <th scope="col">Selected Time</th>
                <th scope="col">Theatre</th>
                <th scope="col">Theatre Type</th>
                <th scope="col">Theatre Price</th>
                <th scope="col">No of Extra Adults</th>
                <th scope="col">Extra Adult Price</th>
                <th scope="col">Decoration</th>
                <th scope="col">Decoration Details</th>
                <th scope="col">Led Name Decoration</th>
                <th scope="col">Led Number Decoration</th>
                <th scope="col">Cake</th>
                <th scope="col">Name on Cake</th>
                <th scope="col">Total Amount</th>
                <th scope="col">Remaining Amount</th>
                <th scope="col">Delete Order</th>
              </tr>
            </thead>
            <tbody>
              {totalOrderDetails.length > 0 ? (
                totalOrderDetails.map((orderDetails) => (
                  <tr key={orderDetails._id}>
                    <td data-label="Transaction ID">
                      {orderDetails?.transactionId}
                    </td>
                    <td data-label="Status">
                      <span className="ms-1 fa fa-check-circle customer-booked-green">
                        {orderDetails?.status}
                      </span>
                    </td>
                    <td data-label="User">{orderDetails?.username}</td>
                    <td data-label="Email">{orderDetails?.email}</td>
                    <td data-label="WhatsApp No">{orderDetails?.whatsappNo}</td>
                    <td data-label="Selected Date">
                      {orderDetails?.selectedDate}
                    </td>
                    <td data-label="Selected Time">
                      {orderDetails?.selectedTime}
                    </td>
                    <td data-label="Theatre">
                      {orderDetails?.selectedTheatre}
                    </td>
                    <td data-label="Theatre Type">
                      {orderDetails?.selectedTheatreType}
                    </td>
                    <td data-label="Theatre Price">
                      {orderDetails?.selectedTheatrePrice}
                    </td>
                    <td data-label="No of Extra Adults">
                      {orderDetails?.extraAdults}
                    </td>
                    <td data-label="Extra Adult Price">
                      ₹{orderDetails?.totalAdultPrice}
                    </td>
                    <td data-label="Decoration">
                      {orderDetails?.selectedDecorationType}
                    </td>
                    <td data-label="Decoration Details">
                      {orderDetails?.selectedDecorationDetails}
                    </td>
                    <td data-label="Decoration Details">
                      {orderDetails?.selectedLedNameDecoration || "NA"}
                    </td>
                    <td data-label="Decoration Details">
                      {orderDetails?.selectedLedNumberDecoration || "NA"}
                    </td>
                    <td data-label="Cake">{orderDetails?.selectedCake}</td>
                    <td data-label="Name on Cake">
                      {orderDetails?.nameonCake}
                    </td>
                    <td data-label="Total Amount">
                      {orderDetails?.totalAmount}
                    </td>
                    <td data-label="Remaining Amount">
                      {orderDetails?.remainingAmount}
                    </td>
                    <td data-label="Delete Order">
                      <button
                        onClick={() => handleDelete(orderDetails?._id)}
                        className="customer-booked-delete-button"
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="21" className="customer-booked-no-data">
                    No Data Fetched!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
      </>
    )
    : (
      <>
      `No Access Token Found `
      <button onClick={handleLogin}>Login</button>
      </>
    )
  );
};

export default AdminCustomerOrders;
