import React, { useState } from "react";
import Heading from "../components/common/Heading";
import CommonHeading from "../components/common/CommonHeading";
import { contact } from "../components/data/Data";
import "../css/contact.page.css";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:miniflicksprivatetheatres@gmail.com?subject=${encodeURIComponent(
      formData.subject
    )}&body=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\n\n${formData.message}`
    )}`;
    window.location.href = mailtoLink;
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  return (
    <>
      <Heading heading="Contact" title="Home" subtitle="Contact" />
      <div className="contact-container-xxl py-5">
        <div className="contact-container contact-grid">
          <div className="col-md-8 contact-info-map">
            <CommonHeading
              heading="Contact Us"
              subtitle="Contact"
              title="  For Any Query"
            />
            <div className="row gy-4">
              {contact.map((item, index) => (
                <div key={index} className="col-md-6">
                  <div className="contact-card">
                    <h6 className="contact-title text-start text-primary text-uppercase">
                      {item.title}
                    </h6>
                    <p>
                      <span className="contact-icon">{item.icon}</span>
                      {item.email}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <br />
            <br/>
            <div className="contact-map-container">
              <iframe
                className="contact-map rounded"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.1102944975137!2d77.70242999999999!3d12.964793600000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae131e0d1e4479%3A0xcba4ab3250d34b75!2sMiniFlicks%20Private%20Theatres!5e0!3m2!1sen!2sin!4v1718877586975!5m2!1sen!2sin"
                style={{ minHeight: "350px", border: "0" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="contact-form-wrapper col-md-4">
            <h3 className="form-title">Send Us a Message</h3>
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="subject">Subject</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  className="form-control"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  className="form-control"
                  rows="5"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
