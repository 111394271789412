import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../../css/admin.customer.details.css";
import { useNavigate } from "react-router-dom";
const AdminCustomerDetails = () => {
  const [customersDetails, setCustomersDetails] = useState([]);
  const awsProdPublicIP = process.env.REACT_APP_AWS_EC2_PUBLIC_IP;
  const awsProdPublicDomain = process.env.REACT_APP_AWS_EC2_DOMAIN;
  const localhost = process.env.REACT_APP_LOCALHOST;
  const accessToken = localStorage.getItem("admin-accessToken")
  const navigate = useNavigate()
  const handleLogin  = () => {
    navigate("/admin")
  }
  useEffect(() => {
    // <---Prod --->
    axios
      .get(`${awsProdPublicDomain}/admin/customers`)
    .then((data) => {
      setCustomersDetails(data.data.message);
    })
    .catch((err) => console.log(err));

    // <--- localhost --->
    // axios
    //   .get(`${localhost}/admin/customers`)
    //   .then((data) => {
    //     setCustomersDetails(data.data.message);
    //   })
    //   .catch((err) => console.log(err));
  }, []);

  return (
    accessToken ? 
    (<>
    <div className="admin-customer-details-body">
      <div className="admin-customer-details-container admin-customer-details-mt-5 admin-customer-details-px-2">
        <div className="admin-customer-details-table-responsive">
          <table className="admin-customer-details-table admin-customer-details-table-borderless">
            <thead>
              <tr className="admin-customer-details-bg-light">
                <th scope="col" width="10%">
                  Customer ID - DB
                </th>
                <th scope="col" width="10%">
                  Customer Name
                </th>
                <th scope="col" width="10%">
                  Contact Number
                </th>
                <th scope="col" width="10%">
                  Email
                </th>
              </tr>
            </thead>
            <tbody>
              {customersDetails.length > 0 ? (
                customersDetails.map((customer) => (
                  <tr key={customer?._id}>
                    <td>{customer?._id}</td>
                    <td>{customer?.username}</td>
                    <td>{customer?.whatsappNo}</td>
                    <td>{customer?.email}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" className="admin-customer-details-no-data">
                    No Data Fetched!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </>)
    : (
      <>
      `No Access Token Found `
      <button onClick={handleLogin}>Login</button>
      </>
    )
  );
};

export default AdminCustomerDetails;
